import React, { useEffect, useState } from 'react'
import url from "../../../url"
import DataTable from 'react-data-table-component'

export default function Client() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column=[
        {
            name:'Nom',
            selector: row=>row.nom,
            sortable:true
        },
        {
            name:'Prénom',
            selector: row=>row.prenom,
            sortable:true
        },
        {
            name:'Téléphone',
            selector: row=>row.telephone,
            sortable:true
        },
        {
            name:'E-mail',
            selector: row=>row.Utilisateur.email,
            sortable:true
        },
        {
            name:'Adresse',
            selector: row=>row.adresse,
            sortable:true
        },
        {
            name:'CIN',
            selector: row=>row.CIN,
            sortable:true
        },
        {
            name:'Date de naissance',
            selector: row=>row.date_naissance,
            sortable:true
        },
        {
            name:'Age',
            selector: row=>(<>{row.age} ans</>),
            sortable:true
        },
        {
            name:'Sexe',
            selector: row=>row.sexe,
            sortable:true
        },
        {
            name: 'Action',
            cell: (row) => (
              <>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-info mr-2"
                    onClick={() => fonction_detail( row.nom, row.prenom, row.telephone,
                    row.Utilisateur.email, row.adresse, row.CIN, row.date_naissance, row.age, row.sexe,
                    row.Utilisateur.validation)}
                    ><i className="fa fa-eye" /></button>
                </span>
                {row.Utilisateur.validation === 1 ? (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => valide_donnee(row.id_utilisateur)}
                        ><i className="fa fa-check" /></button>
                    </span>
                ) : (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => valide_donnee(row.id_utilisateur)}
                        ><i className="fas fa-times" /></button>
                    </span>
                )}
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => fonction_supprime(row.id_utilisateur)}
                    ><i className="fa fa-trash" /></button>
                </span>
              </>
            ),
        }
    ]

    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            const response = await url.get("/inscription/affiche_tous_client");
            setDonnee(response.data);
            setFiltre_donnee(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Donnee())()
    },[])
    const handlefilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const new_data = filtre_donnee.filter(row => 
            row.nom.toLowerCase().includes(searchValue) || 
            row.prenom.toLowerCase().includes(searchValue) ||
            row.adresse.toLowerCase().includes(searchValue)
        );
        setDonnee(new_data);
    };

    //Detail de donnee
    const [nom, setNom]=useState('')
    const [prenom, setPrenom]=useState('')
    const [telephone, setTelephone]=useState('')
    const [email, setEmail]=useState('')
    const [adresse, setAdresse]=useState('')
    const [cin, setCin]=useState('')
    const [date_naissance, setDate_naissance]=useState('')
    const [age, setAge]=useState('') // mandea
    const [sexe, setSexe]=useState('')
    const [validation, setValidation]=useState('')
    const [message, setMessage]=useState('')
    const [alert_message, setAlert_message]=useState(false)
    const [modal_detail, setModal_detail]=useState(false)
    function fonction_detail(nom, prenom, telephone, email, adresse, cin, date_naissance, age, sexe,validation){
        setNom(nom)
        setPrenom(prenom)
        setTelephone(telephone)
        setEmail(email)
        setAdresse(adresse)
        setCin(cin)
        setDate_naissance(date_naissance)
        setAge(age)
        setSexe(sexe)
        setValidation(validation)
        setModal_detail(!modal_detail)
    }
    
    //Valide le donnee
    const [id_utilisateur, setId_utilisateur]=useState('')
    const [modal_valide, setModal_valide]=useState(false)
    function valide_donnee(id_utilisateur){
        setId_utilisateur(id_utilisateur)
        setModal_valide(!modal_valide)
    }
    async function valide_utilisateur(id_utilisateur){
        await url.post("/inscription/valide_utilisateur", {
            id_utilisateur
        })
        await Donnee()
        .then(response => {
            console.log(response.data.message);
        })
        .catch(error => {
            console.error(error);
        });
        setModal_valide(!modal_valide)
        setMessage("Validation avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
    }

    // supprimer le donner
    const [modal_suprime, setModal_suprime]=useState(false)
    function fonction_supprime(id_utilisateur){
        setModal_suprime(!modal_suprime)
        setId_utilisateur(id_utilisateur)
    }
    async function supprime_utilisateur(){
        await url.delete(`/inscription/supprime_utilisateur/${id_utilisateur}`)
        await Donnee()
        setMessage("Suppression avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
        setModal_suprime(!modal_suprime)
    }

  return (
    <>
        {alert_message &&<p className='message_alert'>{message}</p>}
        <div className='tete_body mt-3 row'>
            <h4 className='col-lg-6 col-md-6'>Gestion de client</h4>
            <div className='col-lg-6 col-md-6'>
                <input type='text' 
                placeholder='Recherche...'
                onChange={handlefilter}
                />
                {/* <button className='mr'>
                    <i className='fa fa-plus'/>
                    Ajouter
                </button> */}
            </div>
        </div>

        <div className='body'>
            <DataTable className='datatable'
                columns={column}
                data={donnee}
                // selectableRows
                fixedHeader
                pagination
                customStyles={style}
            ></DataTable>
        </div>

        {/* modal detail */}
        {modal_detail &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_detail()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Detail</h4>
                    <div className='donne_affiche'>
                        <div className='div1'>
                            <label>Nom :</label>
                            <label>{nom}</label>
                        </div>
                        <div className='div1'>
                            <label>Prénom :</label>
                            <label>{prenom}</label>
                        </div>
                        <div className='div1'>
                            <label>Téléphone :</label>
                            <label>{telephone}</label>
                        </div>
                        <div className='div1'>
                            <label>E-mail :</label>
                            <label>{email}</label>
                        </div>
                        <div className='div1'>
                            <label>Adresse :</label>
                            <label>{adresse}</label>
                        </div>
                        <div className='div1'>
                            <label>CIN :</label>
                            <label>{cin}</label>
                        </div>
                        <div className='div1'>
                            <label>Date de naissence :</label>
                            <label>{date_naissance}</label>
                        </div>
                        <div className='div1'>
                            <label>Age :</label>
                            <label>{age} ans</label>
                        </div>
                        <div className='div1'>
                            <label>Sexe :</label>
                            <label>{sexe}</label>
                        </div>
                        <div className='div1'>
                            <label>Validation</label>
                            <label>{validation===1 ? 'Valide' : 'Non valide'}</label>
                        </div>
                    </div>
                    <span className='btn_detail'>
                        <button onClick={()=>fonction_detail()}>Fermer</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal valide */}
        {modal_valide &&(
            <div className='modal_detail'>
                <div onClick={()=>valide_donnee()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Validation</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la validation de l'utilisateur</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>valide_donnee()}>Fermer</button>
                        <button onClick={()=>valide_utilisateur(id_utilisateur)}>Valider</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal supprimer */}
        {modal_suprime &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_supprime()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Suppression</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la suppression de l'utilisateur</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>fonction_supprime()}>Fermer</button>
                        <button onClick={()=>supprime_utilisateur(id_utilisateur)}>Supprimer</button>
                    </span>
                </div>
            </div>
        )}
    </>
  )
}
