import React, { useEffect, useState } from 'react'
import url from "../../url"
import DataTable from 'react-data-table-component'
import Cookies from 'universal-cookie';
import lien from '../../url_image';
import date_pubs from '../../fonction_date';

export default function Produit() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column=[
        {
            name:'Nom',
            selector: row=>row.libelle,
            sortable:true
        },
        {
            name:'Image',
            selector: row=>(<div className='image_data'>
            <img className='' src={`${lien}/images/produits/${row.image1}`} alt={row.image1} />
            </div>),
            // sortable:true
        },
        {
            name:'Prix',
            selector: row=>(<>{row.prix} Ar / {row.Unite.abreviation}</>),
            sortable:true
        },
        {
            name:'Catégorie',
            selector: row=>row.Categorie.libelle_categorie,
            sortable:true
        },
        {
            name:'Déscription',
            selector: row=>row.description,
            sortable:true
        },
        {
            name:'Provenance',
            selector: row=>row.provenance,
            sortable:true
        },
        {
            name:'Date de publication',
            selector: row=>(<>{date_pubs(row.date_pub)}</>),
            sortable:true
        },
        {
            name:'Disponibilité',
            cell:(row)=>(
                <>
                    {row.disponible === 1 ? (
                        <span className='m-1 mr-2 text-nowrap btn-info'>
                        <button
                        type="button"
                        className="btn btn-info mr-2 text-nowrap"
                        onClick={() => disponible_donnee(row.id_produit)}
                        >
                        Disponibile
                        </button>
                    </span>
                    ) : (
                        <span className='m-1 mr-2 text-nowrap'>
                        <button
                        type="button"
                        className="btn btn-danger mr-4 text-nowrap"
                        onClick={() => disponible_donnee(row.id_produit)}
                        >Non disponibile</button>
                    </span>
                    )}
                </>
            )
        },
        {
            name: 'Action',
            cell: (row) => (
              <>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-info mr-2"
                    onClick={() => fonction_detail(row.libelle, row.prix, row.Categorie.libelle_categorie,
                    row.description, row.provenance, date_pubs(row.date_pub), row.image1, row.image2, row.image3)}
                    ><i className="fa fa-eye" /></button>
                </span>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-info mr-2"
                    onClick={() => toggle_modal_modifie(row.libelle, row.prix, row.Categorie.id_categorie,
                    row.description, row.provenance, row.image1, row.image2, row.image3, row.id_produit, row.id_unite)}
                    ><i className="fa fa-edit" /></button>
                </span>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => fonction_supprime(row.id_produit)}
                    ><i className="fa fa-trash" /></button>
                </span>
              </>
            ),
        }
    ]

    //securisation
    const cookies=new Cookies()
    const [id_cookie, setId_cookie] = useState('');
    const [role_cookie, setRole_cookie] = useState();
    useEffect(() => {
        const idFromCookie = cookies.get('id_front');
        const roleFromCookie = cookies.get('role_front');
        setId_cookie(idFromCookie);
        setRole_cookie(roleFromCookie);
      }, [cookies]);
    //securisation

    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            url.get(`/produit/affiche_produit_fournisseur/${id_cookie}`)
            .then((response)=>{
                setDonnee(response.data);
                setFiltre_donnee(response.data);
            })
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        if(id_cookie){
            (async()=> await Donnee())()
        }
    },[id_cookie])
    const handlefilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const new_data = filtre_donnee.filter(row => 
            row.libelle.toLowerCase().includes(searchValue) ||
            row.Categorie.libelle_categorie.toLowerCase().includes(searchValue) ||
            row.description.toLowerCase().includes(searchValue)
        );
        setDonnee(new_data);
    };

    //Affiche categorie
    const [categorie_data, setCategorie_data]=useState([])
    const Load = ()=>{
        url.get("/categorie/affiche").then((response)=>{
            setCategorie_data(response.data)
        }).catch((error)=>{
            console.log(error)
        })
    }
    useEffect(()=>{
        (async()=> await Load())()
    }, [])

    //Affiche unite
    const [unite_data, setUnite_data]=useState([])
    const Unite = ()=>{
        url.get("/unite/affiche").then((response)=>{
            setUnite_data(response.data)
        }).catch((error)=>{
            console.log(error)
        })
    }
    useEffect(()=>{
        (async()=> await Unite())()
    }, [])

    //Detail de donnee
    const [nom, setNom]=useState('')
    const [prix, setPrix]=useState('')
    const [categorie, setCategorie]=useState('')
    const [description, setDescription]=useState('')
    const [provenance, setProvenance]=useState('')
    const [fournisseur, setFournisseur]=useState('')
    const [date_pub, setDate_pub]=useState('')
    const [unite, setUnite]=useState('')
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [message, setMessage]=useState('')
    const [id_produit, setId_produit]=useState()
    const [alert_message, setAlert_message]=useState(false)
    const [modal_detail, setModal_detail]=useState(false)
    function fonction_detail(nom, prix, categorie, description, provenance, date_pub){
        setNom(nom)
        setPrix(prix)
        setCategorie(categorie)
        setDescription(description)
        setProvenance(provenance)
        setFournisseur(fournisseur)
        setDate_pub(date_pub)
        setModal_detail(!modal_detail)
    }

    // supprimer le donner
    const [modal_suprime, setModal_suprime]=useState(false)
    function fonction_supprime(id_produit){
        setModal_suprime(!modal_suprime)
        setId_produit(id_produit)
    }
    async function supprime_utilisateur(){
        await url.delete(`/produit/supprime_produit/${id_produit}`)
        await Donnee()
        setMessage("Suppression avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
        setModal_suprime(!modal_suprime)
    }

    // ajout publicite
    const [modal_ajout, setModal_ajout]=useState(false)
    const newErrors={}
    const [erreur, setErreur]=useState({})
    function toggle_modal_ajout(){
        setModal_ajout(!modal_ajout)
    }
    async function ajout_donnee() {
        setErreur({});

        const formdata = new FormData();
        formdata.append('image1', image1);
        formdata.append('image2', image2);
        formdata.append('image3', image3);
        formdata.append('nom', nom);
        formdata.append('prix', prix);
        formdata.append('description', description);
        formdata.append('provenance', provenance);
        formdata.append('categorie', categorie);
        formdata.append('id_cookie', id_cookie);
        formdata.append('unite', unite)
      
        const newErrors = {};
        if (!nom.trim()) {
          newErrors.nom = "Le nom du produit est requis.";
        }
        if (!prix.trim()) {
          newErrors.prix = "Le prix est requis.";
        }
        if (!description.trim()) {
          newErrors.description = "La description est requise.";
        }
        if (!categorie.trim()) {
          newErrors.categorie = "La catégorie est requise.";
        }
      
        if (Object.keys(newErrors).length > 0) {
          setErreur(newErrors);
        } else {
          try {
            const response = await url.post('/produit/ajout_produit', formdata, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              });
            await Donnee();
            setMessage("Ajout réussi");
            setAlert_message(true);
            setTimeout(() => {
              setAlert_message(false);
            }, 1500);
            setNom('');
            setPrix('');
            setDescription('');
            setProvenance('');
            setCategorie('');
            setImage1('');
            setImage2('');
            setImage3('');
            setModal_ajout(!modal_ajout)
          } catch (error) {
            console.error(error);
          }
        }
    }
    
    const change_categorie = (e)=>{
        let value = e.target.value
        setCategorie(value)
    }

    // Modifie du produit
    const [modal_modifie, setModal_modifie]=useState(false)
    function toggle_modal_modifie(nom, prix, categorie, description, provenance, image1, 
        image2, image3, id_produit, unite){
        setNom(nom)
        setPrix(prix)
        setCategorie(categorie)
        setDescription(description)
        setProvenance(provenance)
        setFournisseur(fournisseur)
        setDate_pub(date_pub)
        setImage1(image1)
        setImage2(image2)
        setImage3(image3)
        setId_produit(id_produit)
        setUnite(unite)
        setModal_modifie(!modal_modifie)
    }
    async function modifie_donnee(){
        setErreur({});
        const formdata = new FormData();
        formdata.append('image1', image1);
        formdata.append('image2', image2);
        formdata.append('image3', image3);
        formdata.append('nom', nom);
        formdata.append('prix', prix);
        formdata.append('description', description);
        formdata.append('provenance', provenance);
        formdata.append('categorie', categorie);
        formdata.append('id_cookie', id_cookie);
        formdata.append('id_produit', id_produit);
        formdata.append('unite', unite)
    
        const newErrors = {};
        if (!nom.trim()) {
          newErrors.nom = "Le nom du produit est requis.";
        }
        if (!description.trim()) {
          newErrors.description = "La description est requise.";
        }
        // if (!categorie.trim()) {
        //   newErrors.categorie = "La catégorie est requise.";
        // }
      
        if (Object.keys(newErrors).length > 0) {
          setErreur(newErrors);
        } else {
          try {
            const response = await url.post('/produit/modifie_produit', formdata, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            await Donnee();
            setMessage("Modifie réussi");
            setAlert_message(true);
            setTimeout(() => {
              setAlert_message(false);
            }, 1500);
            setNom('');
            setPrix('');
            setDescription('');
            setProvenance('');
            setCategorie('');
            setImage1('');
            setImage2('');
            setImage3('');
            setModal_modifie(!modal_modifie)
        } catch (error) {
            console.error(error);
        }
        }
    }

    const [modal_disponible, setModal_disponible]=useState(false)
    function disponible_donnee(id_produit){
        setModal_disponible(!modal_disponible)
        setId_produit(id_produit)
    }

    async function change_disponible(){
        await url.post("/produit/modifie_disponibile_produit", {
            id_produit
        })
        await Donnee()
        .then(response => {
            // console.log(response.data.message);
        })
        .catch(error => {
            console.error(error);
        });
        setModal_disponible(!modal_disponible)
        setMessage("Validation avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
    }

  return (
    <>
        {alert_message &&<p className='message_alert'>{message}</p>}
        <div className='tete_body mt-3 row'>
            <h4 className='col-lg-6 col-md-6'>Géstion de produit</h4>
            <div className='col-lg-6 col-md-6'>
                <input type='text' 
                placeholder='Recherche...'
                onChange={handlefilter}
                />
                <button className='mr' onClick={toggle_modal_ajout}>
                    <i className='fa fa-plus'/>
                    Ajouter
                </button>
            </div>
        </div>

        <div className='body'>
            <DataTable
                columns={column}
                data={donnee}
                // selectableRows
                fixedHeader
                pagination
                customStyles={style}
            ></DataTable>
        </div>

        {/* modal detail */}
        {modal_detail &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_detail()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Detail</h4>
                    <div className='donne_affiche'>
                        <div className='div1'>
                            <label>Nom du produit :</label>
                            <label>{nom}</label>
                        </div>
                        <div className='div1'>
                            <label>Prix :</label>
                            <label>{prix} Ar</label>
                        </div>
                        <div className='div1'>
                            <label>Categorie :</label>
                            <label>{categorie}</label>
                        </div>
                        <div className='div1'>
                            <label>Description :</label>
                            <label>{description}</label>
                        </div>
                        <div className='div1'>
                            <label>Provenance :</label>
                            <label>{provenance}</label>
                        </div>
                        <div className='div1'>
                            <label>Date du publication :</label>
                            <label>{date_pub}</label>
                        </div>
                    </div>
                    <span className='btn_detail'>
                        <button onClick={()=>fonction_detail()}>Fermer</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal ajouter */}
        {modal_ajout &&(
            <div className='modal_detail'>
                <div onClick={()=>toggle_modal_ajout()} className='ferme_modal'></div>
                <div className='body_modal_details'>
                    <h4 align="center">Ajout</h4>
                    <div className='inscription_inputs'>
                        <div className='cont_input'>
                            <label>Nom :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Titre...'
                            value={nom}
                            onChange={(e)=>setNom(e.target.value)}
                            />
                            {erreur.nom && 
                            <p className="error_message">{erreur.nom}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Prix :</label> <br/>
                            <input 
                            type='number' 
                            placeholder='Prix...'
                            value={prix}
                            onChange={(e)=>setPrix(e.target.value)}
                            />
                            {erreur.prix && 
                            <p className="error_message">{erreur.prix}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image 1 :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            // value={image1}
                            onChange={(e)=>setImage1(e.target.files[0])}
                            />
                            {erreur.image1 && 
                            <p className="error_message">{erreur.image}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image 2 :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            // value={image2}
                            onChange={(e)=>setImage2(e.target.files[0])}
                            />
                        </div>
                        <div className='cont_input'>
                            <label>Image 3 :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            // value={image3}
                            onChange={(e)=>setImage3(e.target.files[0])}
                            />
                        </div>
                        <div className='cont_input'>
                            <label>Unite :</label> <br/>
                            <select value={unite} onChange={(e)=>setUnite(e.target.value)}>
                                <option>-- Select un unite --</option>
                                {unite_data.map((item)=>(
                                    <option value={item.id_unite}>{item.libelle_unite}</option>
                                ))}
                            </select>
                            {erreur.unite && 
                            <p className="error_message">{erreur.unite}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Categorie :</label> <br/>
                            <select value={categorie} onChange={change_categorie}>
                                <option>-- Select un categorie --</option>
                                {categorie_data.map((item)=>(
                                    <option value={item.id_categorie}>{item.libelle_categorie}</option>
                                ))}
                            </select>
                            {erreur.categorie && 
                            <p className="error_message">{erreur.categorie}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Provenance :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Preovenance...'
                            value={provenance}
                            onChange={(e)=>setProvenance(e.target.value)}
                            />
                            {erreur.provenance && 
                            <p className="error_message">{erreur.provenance}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Description :</label> <br/>
                            <textarea 
                            placeholder='Description...'
                            value={description}
                            onChange={(e)=>setDescription(e.target.value)}
                            />
                            {erreur.descriptions && 
                            <p className="error_message">{erreur.descriptions}</p>}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <button onClick={()=>toggle_modal_ajout()}>Fermer</button>
                            <button onClick={()=>ajout_donnee()}><i className='fa fa-plus'/> Ajout</button>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {/* Modal Modiffier */}
        {modal_modifie &&(
            <div className='modal_detail'>
                <div onClick={()=>toggle_modal_modifie()} className='ferme_modal'></div>
                <div className='body_modal_details alert custom-alert'>
                    <h4 align="center">Modification</h4>
                    <div className='inscription_inputs'>
                        <div className='cont_input'>
                            <label>Nom :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Titre...'
                            value={nom}
                            onChange={(e)=>setNom(e.target.value)}
                            />
                            {erreur.nom && 
                            <p className="error_message">{erreur.nom}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Prix :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Lieu...'
                            value={prix}
                            onChange={(e)=>setPrix(e.target.value)}
                            />
                            {erreur.prix && 
                            <p className="error_message">{erreur.prix}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image 1 :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            // value={image1}
                            onChange={(e)=>setImage1(e.target.files[0])}
                            />
                            {erreur.image && 
                            <p className="error_message">{erreur.image}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image 2 :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            // value={image2}
                            onChange={(e)=>setImage2(e.target.files[0])}
                            />
                            {erreur.image && 
                            <p className="error_message">{erreur.image}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image 3 :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            // value={image3}
                            onChange={(e)=>setImage3(e.target.files[0])}
                            />
                            {erreur.image && 
                            <p className="error_message">{erreur.image}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Unite :</label> <br/>
                            <select value={unite} onChange={(e)=>setUnite(e.target.value)}>
                                <option>-- Select un unite --</option>
                                {unite_data.map((item)=>(
                                    <option value={item.id_unite}>{item.libelle_unite}</option>
                                ))}
                            </select>
                            {erreur.unite && 
                            <p className="error_message">{erreur.unite}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Categorie :</label> <br/>
                            <select value={categorie} onChange={(e)=>setCategorie(e.target.value)}>
                                <option>-- Select un categorie --</option>
                                {categorie_data.map((item)=>(
                                    <option value={item.id_categorie}>{item.libelle_categorie}</option>
                                ))}
                            </select>
                            {erreur.categorie && 
                            <p className="error_message">{erreur.categorie}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Provenance :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Preovenance...'
                            value={provenance}
                            onChange={(e)=>setProvenance(e.target.value)}
                            />
                            {erreur.provenance && 
                            <p className="error_message">{erreur.provenance}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Description :</label> <br/>
                            <textarea 
                            placeholder='Description...'
                            value={description}
                            onChange={(e)=>setDescription(e.target.value)}
                            />
                            {erreur.descriptions && 
                            <p className="error_message">{erreur.descriptions}</p>}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <button onClick={()=>toggle_modal_modifie()}>Fermer</button>
                            <button onClick={()=>modifie_donnee()}><i className='fa fa-edit'/> Modifier</button>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {/* modal supprimer */}
        {modal_suprime &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_supprime()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Suppression</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la suppression</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>fonction_supprime()}>Fermer</button>
                        <button onClick={()=>supprime_utilisateur()}>Supprimer</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal disponible */}
        {modal_disponible &&(
            <div className='modal_detail'>
                <div onClick={()=>disponible_donnee()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Suppression</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Change la disponibilite</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>disponible_donnee()}>Fermer</button>
                        <button onClick={()=>change_disponible()}>Change</button>
                    </span>
                </div>
            </div>
        )}
    </>
  )
}
