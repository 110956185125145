import React, { useEffect, useState } from 'react'
import "../css/Categorie.css"
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import url from '../../../url';
import lien from '../../../url_image';

export default function Categorie() {
   //Affiche categorie
   const [categorie, setCategorie]=useState([])
   useEffect(()=>{
       url.get("/categorie/affiche").then((response)=>{
           setCategorie(response.data)
       }).catch((error)=>{
           console.log(error)
       })
   }, [])

   const [recherche, setRecherche]=useState('')
  const handle_recherche = (e)=>{
    let value = e.target.value
    setRecherche(value)
  }
  return (
    <>
      <div className='container container_categorie mt-4 mb-5 pb-3'>
        <div className='tete_categorie'>
            <h3>Catégorie</h3>
            <div className='cherche_categorie'>
                <input onChange={handle_recherche} type='text' placeholder='Recherche de catégorie ...'/>
                <i className='fa fa-search icone_categorie'/>
            </div>
        </div>
        <div className='body_categorie mt-3 row' align="center">
        <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              EffectCube,
              Autoplay,
            ]}
            spaceBetween={50}
            navigation
            autoplay={true}
            // scrollbar={{ draggable: true }}
            slidesPerView={4}
            breakpoints={{
                1300: { slidesPerView: 4 }, 
                1000: { slidesPerView: 3 },
                820: { slidesPerView: 3 }, 
                992: { slidesPerView: 2 }, 
                520: { slidesPerView: 2 }, 
                400: { slidesPerView: 1 } ,
                350: {slidesPerView:1},
                10: {slidesPerView:1}
            }}
          >
            {categorie.filter((val)=>{
            return val.libelle_categorie.toLowerCase().includes(recherche.toLowerCase())
        }).map((item, id) => (
              <SwiperSlide key={id} className="" align="center">
                <div className='sous_body_categorie col'>
                  <img src={`${lien}/images/produits/${item.image}`} alt={item.image} />
                    <div className='nom_categorie'><p>{item.libelle_categorie}</p></div>
                </div>
              </SwiperSlide>
          ))}
            </Swiper>
        </div>
      </div>
    </>
  )
}
