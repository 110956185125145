const data_pub=[
    {
        id:1,
        titre:'Mora varotra',
        lieu:'Suppreme center Box 301',
        photo:'image/img1.jpg',
        contenu:'Contenu 1'
    },
    {
        id:2,
        titre:'Mora telephone',
        lieu:'Suppreme center Box 900',
        photo:'image/img2.jpeg',
        contenu:'Contenu 2'
    },
    {
        id:3,
        titre:'Telephone ho anao',
        lieu:'Suppreme center Box 320',
        photo:'image/img3.jpg',
        contenu:'Contenu 3'
    },
    {
        id:4,
        titre:'Cartable tsara be',
        lieu:'Suppreme center Box 311',
        photo:'image/img4.jpeg',
        contenu:'Contenu 4'
    },
    {
        id:5,
        titre:'Pate min sao',
        lieu:'Suppreme center Box 308',
        photo:'image/img5.jpg',
        contenu:'Contenu 5'
    }
]

export default data_pub