import React, { useEffect, useState } from 'react'
import "../css/Historique.css"
import url from '../../../url'
import Cookies from 'universal-cookie';
import DataTable from 'react-data-table-component'
import date_pubs from '../../../fonction_date';

export default function Historique() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column = [
        {
            name : 'Date de commande',
            selector: row=>(<>{date_pubs(row.createdAt)}</>),
        },
        {
            name : 'Total a payer',
            selector : row=>(<>{row.Payement.total_paye} Ar</>)
        },
        {
            name : 'Type de payement',
            selector : row=>row.Payement.mode_payement
        },
        {
            name : 'Validation',
            cell:(row)=>(
                <>
                    {row.validation === 0 ? (<span>Non valide</span>
                    ) : (<span>Valide</span>)}
                </>
            )
        },
        {
            name : 'Statut',
            cell:(row)=>(
                <>
                    {row.Payement.status === 0 ? (<span>Non payer</span>
                    ) : (<span>Payer</span>)}
                </>
            )
        },
        {
            name : '',
            cell:(row)=>(
                <>
                    <span onClick={()=>toggle_modal_historique(row.id_commande, date_pubs(row.createdAt))}>
                        <i className='fa fa-eye'/>
                    </span>
                </>
            )
        }
    ]

    const detail_commande = [
        {
            name : 'Nom du produit',
            selector : row=>row.Produit.libelle
        },
        {
            name: 'Prix',
            selector : row=>(<>{row.Produit.prix} Ar</>)
        },
        {
            name: 'Quantite',
            selector : row=>(<>{row.quantite} {row.Produit.Unite.abreviation}</>)
        },
        {
            name : 'Sous total',
            cell : (row)=>(<>{row.Produit.prix * row.quantite} Ar</>)
        },
        {
            name : 'Provenance',
            selector : row=>row.provenance
        }
    ]

    //securisation
    const cookies=new Cookies()
    const [id_cookie, setId_cookie] = useState('');
    const [role_cookie, setRole_cookie] = useState();
    useEffect(() => {
        const idFromCookie = cookies.get('id_front');
        const roleFromCookie = cookies.get('role_front');
        setId_cookie(idFromCookie);
        setRole_cookie(roleFromCookie);
      }, [cookies]);
    //securisation

    //Affiche commande
    const [commande, setCommande] = useState([]);

    // fonction_total_commande()
    const Load = ()=>{
        url.get(`/commande/affiche_commande_client/${id_cookie}`)
        .then((response) => {
            setCommande(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    useEffect(()=>{
        if(id_cookie){
            (async()=> await Load())()
        }
    }, [id_cookie])
    // const total_com = commande.reduce((total, item) => {
    //     return total + ();
    // }, 0);
    const total_com = commande.reduce((total, item) => {
        return total + item.Payement.total_paye;
    }, 0);

    const [modal_detail_historique, setModal_detail_historique]=useState(false)
    const [detail_com, setDetail_Com]=useState([])
    const [date_com, setDate_com]=useState('')
    const total_prix = detail_com.reduce((total, item) => {
        return total + (item.Produit.prix * item.quantite);
    }, 0);
    
    const toggle_modal_historique = async (id_commande, date) => {
        try {
            url.get(`/commande/affiche_detail_commande_client/${id_commande}`)
            .then((response) => {
                setDetail_Com(response.data);
                setDate_com(date)
                setModal_detail_historique(!modal_detail_historique);
            })
            .catch((error) => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    }
  return (
    <>
      <div>
        <div className='mt-4 mb-4 container_panier'>
            <div className=''>
                <div className='detail_produit'>
                    <DataTable
                        columns={column}
                        data={commande}
                        // selectableRows
                        fixedHeader
                        pagination
                        customStyles={style}
                    ></DataTable>
                </div>
            </div>
            <div className='mt-3' align="left">
                <table className='sous_table'>
                    <tr align="center">
                        <th>Total de commande à effectuer</th>
                        <th>Total à payer</th>
                    </tr>
                    <tr align="center">
                        <td>{commande.length}</td>
                        <td>{total_com} Ar</td>
                    </tr>
                </table>
            </div>
        </div>
      </div>

      {/* fenetre modal de detail de l'historique */}
    {modal_detail_historique &&
      <div className='model_detail_historique'>
        <div onClick={toggle_modal_historique} className='ferme_modal'></div>
        <div className='body_modal'>
            <h4 className='mb-4' align="center">Commande du {date_com}</h4>
                <div className='row container_panier'>
                <div className=''>
                    <div className='detail_produit'>
                        <DataTable
                        columns={detail_commande}
                        data={detail_com}
                        fixedHeader
                        // pagination
                        customStyles={style}
                    ></DataTable>
                        <div className='mt-3'>
                            <label>Total : </label>
                            <label> {total_prix} Ar</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    }
    </>
  )
}
