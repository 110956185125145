import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import "./Statistique.css"
import url from "../../url";

ChartJS.register(BarElement, CategoryScale,LinearScale, Tooltip, Legend)

const BarChart= () =>{
    //Affiche de commande
    const [commande, setCommande]=useState([])
    async function Commande() {
        try {
            const response = await url.get("/commande/affiche_commande_back");
            setCommande(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Commande())()
    },[])

    const date_pubs = (text)=>{
        const texts=text.split('T')
        const dates1=texts[0].split('-')
        const dates=dates1[1]
        return dates
    }

    const moisStats = {
        '01': 0, // Janvier
        '02': 0, // Février
        '03': 0, // Mars
        '04': 0, // Avril
        '05': 0, // Mai
        '06': 0, // Juin
        '07': 0, // Juillet
        '08': 0, // Août
        '09': 0, // Septembre
        '10': 0, // Octobre
        '11': 0, // Novembre
        '12': 0, // Décembre
    };

    commande.forEach((nbre) => {
        const month = date_pubs(nbre.date_com);
        if (moisStats.hasOwnProperty(month)) {
            moisStats[month] += 1;
        }
    });

    const chartData = {
        labels : ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", 
        "Septembre", "Octobre", "Novembre", "Decembre"],
        datasets : [
            {
                label : 'Statistique des commandes effectués par mois',
                backgroundColor : 'cyan',
                data : [moisStats['01'], moisStats['02'], moisStats['03'], moisStats['04'], 
                moisStats['05'], moisStats['06'], moisStats['07'], moisStats['08'], moisStats['09'], 
                moisStats['10'], moisStats['11'], moisStats['12']]
            }
        ]
    }
    const chartOptions= {
        // responsive : true,
        maintainAspectRatio : false,
        // scales : {
        //     y: {
        //         beginAtZero : true,
        //     }
        // }
    }

    //Affiche de client
    const [client, setClient]=useState([])
    async function Client() {
        try {
            const response = await url.get("/inscription/affiche_tous_client");
            setClient(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Client())()
    },[])

    //Affiche de fournisseur
    const [fournisseur, setFournisseur]=useState([])
    async function Fournisseur() {
        try {
            const response = await url.get("/fournisseur/affiche_tous_fournisseur");
            setFournisseur(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Fournisseur())()
    },[])

    //Affiche de categorie
    const [categorie, setCategorie]=useState([])
    async function Categorie() {
        try {
            const response = await url.get("/categorie/affiche");
            setCategorie(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Categorie())()
    },[])

    //Affiche de publicite
    const [publicite, setPublicite]=useState([])
    async function Publicite() {
        try {
            const response = await url.get("/publicite/affiche");
            setPublicite(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Publicite())()
    },[])

    //Affiche de Produit
    const [produit, setProduit]=useState([])
    async function Produit() {
        try {
            const response = await url.get("/produit/tous_produit_back");
            setProduit(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Produit())()
    },[])

    //Affiche de unite
    const [unite, setUnite]=useState([])
    async function Unite() {
        try {
            const response = await url.get("/unite/affiche");
            setUnite(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Unite())()
    },[])

    return(
        <>
            <div className="row container_dash mb-5">
                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{client.length}</libel> <br/>
                            <libel>Client</libel>
                        </span>
                        <span>
                            <img src='images/icone/user.png' className='icones_sides' />
                        </span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{fournisseur.length}</libel> <br/>
                            <libel>Fournisseur</libel>
                        </span>
                        <span>
                            <img src='images/icone/fournisseur.png' className='icones_sides' />
                        </span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{commande.length}</libel> <br/>
                            <libel>Commande</libel>
                        </span>
                        <span>
                            {/* <i className="fa fa-shopping-cart"/> */}
                            <img src='images/icone/commande.png' className='icones_sides' />
                        </span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{produit.length}</libel> <br/>
                            <libel>Produit</libel>
                        </span>
                        <span>
                            <img src='images/icone/produit.png' className='icones_sides' />
                        </span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{categorie.length}</libel> <br/>
                            <libel>Catégorie</libel>
                        </span>
                        <span>
                            <img src='images/icone/categories.png' className='icones_sides' />
                        </span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{publicite.length}</libel> <br/>
                            <libel>Publicité</libel>
                        </span>
                        <span>
                            <img src='images/icone/publicit.png' className='icones_sides' />
                        </span>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 div">
                    <div className="m-2">
                        <span>
                            <libel>{unite.length}</libel> <br/>
                            <libel>Unité</libel>
                        </span>
                        <span>
                            <img src='images/icone/mesure.png' className='icones_sides' />
                        </span>
                    </div>
                </div>
            </div>
            <Bar data={chartData} 
                // options={chartOptions}
            />
        </>
    )
}

export default BarChart;