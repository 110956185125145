import React, { useEffect, useState } from 'react'
import {BrowserRouter, Routes, Route} from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import Acceuil from './Front_office/Acceuil/js/Acceuil'
import Shop from './Front_office/Shop/js/Shop'
import Panier from './Front_office/Shop/js/Panier'
import Detail_produit from './Front_office/Shop/js/Detail_produit'
import Apropos from './Front_office/Apropos_contact/js/Apropos'
import Contact from './Front_office/Apropos_contact/js/Contact'
import Inscription from './Front_office/Inscription_login/js/Inscription'
import Login from './Front_office/Inscription_login/js/Login'
import Profil from './Front_office/Profil/js/Profil'
import Payement from './Front_office/Payement/js/Payement'
import Inscription_fournisseur from './Fournisseur/Inscription_fournisseur/js/Inscription'
import Gestion_client from './Back_office/Client/js/Gestion_client'
import Gestion_fournisseur from './Back_office/Fournisseur/Gestion_fournisseur'
import Gestion_produit from './Back_office/Produit/Gestion_produit'
import Gestion_commande from './Back_office/Commande/Gestion_commande'
import Gestion_publicite from './Back_office/Publicite/Gestion_publicite'
import Gestion_produit_fournisseur from './Fournisseur/Produit/Gestion_produit'
import Gestion_profil from './Fournisseur/Profil/Gestion_profil'
import Cookies from 'universal-cookie'
import Gestion_categorie from './Back_office/Categorie/Gestion_categorie'
import Gestion_statistique from './Back_office/Statistique/Gestion_statistique'
import Gestion_unite from './Back_office/Unite/Gestion_unite'

export default function App() {
  const cookies = new Cookies();
  const [id, setId] = useState('');
  const [role, setRole]=useState('')

  // Variables d'affichage des liens
  const [visible_url_client, setVisible_url_client] = useState(false);
  const [visible_url_admin, setVisible_url_admin] = useState(false);
  const [visible_url_fournisseur, setVisible_url_fournisseur] = useState(false);

  useEffect(() => {
      const id_cookie = cookies.get('id_front');
      const role_cookie = cookies.get('role_front');
      setId(id_cookie || '');
      setRole(role_cookie || '')
      // Mettre à jour les variables d'affichage en fonction du rôle
      if (role==="client") {
        setVisible_url_client(true);
        setVisible_url_admin(false);
        setVisible_url_fournisseur(false);
      }else if (role==="admin"){
        setVisible_url_client(false);
        setVisible_url_admin(true);
        setVisible_url_fournisseur(false);
      }else if (role==="fournisseur"){
        setVisible_url_client(false);
        setVisible_url_admin(false);
        setVisible_url_fournisseur(true);
      }else {
        setVisible_url_client(false);
        setVisible_url_admin(false);
        setVisible_url_fournisseur(false);
      }
  }, [role]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Front_office */}
          <Route exact path='/' element={<Acceuil/>}/>
          <Route path='/shop' element={<Shop/>}/>
          <Route path='/panier' element={<Panier/>}/>
          <Route path='/detail_produit' element={<Detail_produit/>}/>
          <Route path='/apropos' element={<Apropos/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/inscription' element={<Inscription/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/profil' element={ visible_url_client ? <Profil/> : <Login/> }/>
          <Route path="/payement" element={ visible_url_client ? <Payement/> : <Login/> }/>

          {/* Back_office */}
          <Route path='/inscription_fournisseur' element={<Inscription_fournisseur/>}/>
          <Route path="/gestion_client" element={ visible_url_admin ? <Gestion_client/> : <Login/> }/>
          <Route path="/gestion_fournisseur" element={ visible_url_admin ? <Gestion_fournisseur/> : <Login/> }/>
          <Route path="/gestion_produit" element={ visible_url_admin ? <Gestion_produit/> : <Login/> }/>
          <Route path="/gestion_commande" element={ visible_url_admin ? <Gestion_commande/> : <Login/> }/>
          <Route path="/gestion_publicite" element={ visible_url_admin ? <Gestion_publicite/> : <Login/> }/>
          <Route path="/gestion_categorie" element={ visible_url_admin ? <Gestion_categorie/> : <Login/> }/>
          <Route path="/gestion_statistique" element={<Gestion_statistique/>}/>
          <Route path="/gestion_unite" element={<Gestion_unite/>}/>

          {/* Fournisseur */}
          <Route path="/gestion_produit_fournisseur" element={ visible_url_fournisseur ? <Gestion_produit_fournisseur/> : <Login/> }/>
          <Route path="/profil_fournisseur" element={ visible_url_fournisseur ? <Gestion_profil/> : <Login/> }/>
        </Routes>
      </BrowserRouter>
    </>
  )
}
