import React, { useEffect, useState } from 'react'
import url from "../../url"
import DataTable from 'react-data-table-component'
import lien from '../../url_image';
import date_pubs from '../../fonction_date';

export default function Produit() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column=[
        {
            name:'Nom',
            selector: row=>row.libelle,
            sortable:true
        },
        {
            name:'Image',
            selector: row=>(<div className='image_data'>
            <img className='' src={`${lien}/images/produits/${row.image1}`} alt={row.image1} />
            </div>)
        },
        {
            name:'Prix',
            selector: row=>(<>{row.prix} Ar / {row.Unite.abreviation}</>),
            sortable:true
        },
        {
            name:'Catégorie',
            selector: row=>row.Categorie.libelle_categorie,
            sortable:true
        },
        {
            name:'Déscription',
            selector: row=>row.description,
            sortable:true
        },
        {
            name:'Provenance',
            selector: row=>row.provenance,
            sortable:true
        },
        {
            name:'Auteur',
            selector: row=>row.Fournisseur.nom,
            sortable:true
        },
        {
            name:'Date de publication',
            selector: row=>(<>{date_pubs(row.date_pub)}</>),
            sortable:true
        },
        {
            name:'Disponibilité',
            cell:(row)=>(
                <>
                    {row.disponible === 1 ? (
                        <span className='m-1 text-nowrap'>
                        <button
                        type="button"
                        className="btn btn-info mr-2 nowrap-text"
                        >Disponibile</button>
                    </span>
                    ) : (
                        <span className='m-1 text-nowrap'>
                        <button
                        type="button"
                        className="btn btn-danger mr-2"
                        >Non disponibile
                        </button>
                    </span>
                    )}
                </>
            )
        },
        {
            name: 'Action',
            cell: (row) => (
              <>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-info mr-2"
                    onClick={() => fonction_detail(row.libelle, row.prix, row.Categorie.libelle_categorie,
                    row.description, row.provenance, row.Fournisseur.nom, date_pubs(row.date_pub))}
                    ><i className="fa fa-eye" /></button>
                </span>
                {row.validation === 1 ? (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => valide_donnee(row.id_produit)}
                        ><i className="fa fa-check" /></button>
                    </span>
                ) : (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => valide_donnee(row.id_produit)}
                        ><i className="fas fa-times" /></button>
                    </span>
                )}
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => fonction_supprime(row.id_produit)}
                    ><i className="fa fa-trash" /></button>
                </span>
              </>
            ),
        }
    ]

    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            const response = await url.get("/produit/tous_produit_back");
            setDonnee(response.data);
            setFiltre_donnee(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Donnee())()
    },[])
    const handlefilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const new_data = filtre_donnee.filter(row => 
            row.libelle.toLowerCase().includes(searchValue) ||
            row.description.toLowerCase().includes(searchValue) ||
            row.provenance.toLowerCase().includes(searchValue) ||
            row.Categorie.libelle_categorie.toLowerCase().includes(searchValue)
        );
        setDonnee(new_data);
    };

    //Detail de donnee
    const [nom, setNom]=useState('')
    const [prix, setPrix]=useState('')
    const [categorie, setCategorie]=useState('')
    const [description, setDescription]=useState('')
    const [provenance, setProvenance]=useState('')
    const [fournisseur, setFournisseur]=useState('')
    const [date_pub, setDate_pub]=useState('')
    const [message, setMessage]=useState('')
    const [alert_message, setAlert_message]=useState(false)
    const [modal_detail, setModal_detail]=useState(false)
    function fonction_detail(nom, prix, categorie, description, provenance, fournisseur, date_pub){
        setNom(nom)
        setPrix(prix)
        setCategorie(categorie)
        setDescription(description)
        setProvenance(provenance)
        setFournisseur(fournisseur)
        setDate_pub(date_pub)
        setModal_detail(!modal_detail)
    }
    
    //Valide le donnee
    const [id_produit, setId_produit]=useState('')
    const [modal_valide, setModal_valide]=useState(false)
    function valide_donnee(id_produit){
        setId_produit(id_produit)
        setModal_valide(!modal_valide)
    }
    async function valide_utilisateur(id_utilisateur){
        await url.post("/produit/modifie_validation_produit", {
            id_produit
        })
        await Donnee()
        .then(response => {
            // console.log(response.data.message);
            setMessage("Validation avec succee")
            setAlert_message(true)
            setTimeout(()=>{
                setAlert_message(false)
            }, 1500)
        })
        .catch(error => {
            console.error(error);
        });
        setModal_valide(!modal_valide)
    }

    // supprimer le donner
    const [modal_suprime, setModal_suprime]=useState(false)
    function fonction_supprime(id_produit){
        setModal_suprime(!modal_suprime)
        setId_produit(id_produit)
    }
    async function supprime_utilisateur(){
        await url.delete(`/produit/supprime_produit/${id_produit}`)
        await Donnee()
        setMessage("Suppression avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
        setModal_suprime(!modal_suprime)
    }

  return (
    <>
        {alert_message &&<p className='message_alert'>{message}</p>}
        <div className='tete_body mt-3 row'>
            <h4 className='col-lg-6 col-md-6'>Gestion de produit</h4>
            <div className='col-lg-6 col-md-6'>
                <input type='text' 
                placeholder='Recherche...'
                onChange={handlefilter}
                />
                {/* <button className='mr'>
                    <i className='fa fa-plus'/>
                    Ajouter
                </button> */}
            </div>
        </div>

        <div className='body'>
            <DataTable
                columns={column}
                data={donnee}
                // selectableRows
                fixedHeader
                pagination
                customStyles={style}
            ></DataTable>
        </div>

        {/* modal detail */}
        {modal_detail &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_detail()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Detail</h4>
                    <div className='donne_affiche'>
                        <div className='div1'>
                            <label>Nom du <span className='text-nowrap'>produit :</span></label>
                            <label>{nom}</label>
                        </div>
                        <div className='div1'>
                            <label className='text-nowrap'>Prix :</label>
                            <label>{prix} Ar</label>
                        </div>
                        <div className='div1'>
                            <label className='text-nowrap'>Categorie :</label>
                            <label>{categorie}</label>
                        </div>
                        <div className='div1'>
                            <label className='text-nowrap'>Déscription :</label>
                            <label>{description}</label>
                        </div>
                        <div className='div1'>
                            <label className='text-nowrap'>Provenance :</label>
                            <label>{provenance}</label>
                        </div>
                        <div className='div1'>
                            <label>Nom du fournisseur :</label>
                            <label>{fournisseur}</label>
                        </div>
                        <div className='div1'>
                            <label>Date de <span className='text-nowrap'>publication :</span></label>
                            <label>{date_pub}</label>
                        </div>
                    </div>
                    <span className='btn_detail'>
                        <button onClick={()=>fonction_detail()}>Fermer</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal valide */}
        {modal_valide &&(
            <div className='modal_detail'>
                <div onClick={()=>valide_donnee()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Validation</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la validation de l'utilisateur</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>valide_donnee()}>Fermer</button>
                        <button onClick={()=>valide_utilisateur(id_produit)}>Valider</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal supprimer */}
        {modal_suprime &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_supprime()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Suppression</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la suppression de l'utilisateur</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>fonction_supprime()}>Fermer</button>
                        <button onClick={()=>supprime_utilisateur(id_produit)}>Supprimer</button>
                    </span>
                </div>
            </div>
        )}
    </>
  )
}
