import React, { useEffect, useState } from 'react'
import Rating from "./Rating"
import Navbar from '../../Layout/js/Navbar'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectCube, Autoplay } from "swiper/modules"
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../css/Detail_produit.css"
import { useLocation } from 'react-router-dom'
import Produit_populaire from '../../Acceuil/js/Produit_populaire'
import Footer from '../../Layout/js/Footer'
import lien from '../../../url_image'
import url from '../../../url';
import Btn_panier_detail from './btn_panier_detail';

export default function Detail_produit() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id_produit = queryParams.get('id_produit');
    //Affiche categorie
  const [post_produit, setPost_produit]=useState([])
  const Load = ()=>{
    url.get(`/produit/affiche_detail_produit/${id_produit}`)
    .then((response) => {
        setPost_produit(response.data);
    })
    .catch((error) => {
        console.log(error);
    });
}
useEffect(()=>{
    if(id_produit){
        (async()=> await Load())()
    }
}, [id_produit])
    //Panier
  const [list_produit, setListe_produit]=useState([])
  const [isErreur, setIseErreur]=useState(false)
  const storedListProduit = sessionStorage.getItem('list_produit');
  useEffect(() => {
      if (storedListProduit) {
          const parsedListProduit = JSON.parse(storedListProduit);
          setListe_produit(parsedListProduit);
      }
  }, []);

    //Ajout de panier
  const ajout_panier = (item) => {
    let isExist = false;
  
    const storedListProduit = sessionStorage.getItem('list_produit');
    if (storedListProduit) {
      const parsedListProduit = JSON.parse(storedListProduit);
      
      for (const produit of parsedListProduit) {
        if (item.id_produit === produit.id_produit) {
          isExist = true;
          setIseErreur(true);
          setTimeout(() => {
            setIseErreur(false);
          }, 1500);
          break;
        }
      }
    }
    if (!isExist) {
      setListe_produit([...list_produit, item]);
      sessionStorage.setItem('list_produit', JSON.stringify([...list_produit, item]));
    }
  }; 
    let image1 = post_produit.image1
    let image2 = post_produit.image2
    let image3 = post_produit.image3
    console.log('image 2 : ',image2)
    // console.log('categorie : ',post_produit.Categorie.image)
    let image_categorie =post_produit.Categorie ? post_produit.Categorie.image : '';
    if(image2===null){
        image2=image_categorie
    }
    if(image3===null){
        image3=image_categorie
    }
    console.log('image 2 : ',image2)
    const images=[
        {
            id:1,
            image:image1
        },
        {
            id:2,
            image:image2
        },
        {
            id:3,
            image:image3
        }
    ]
  return (
    <>
    {isErreur && <div className='erreur'>Le produit existe dejat dans le panier</div>}
        <Navbar size={list_produit.length}/>
        <div className='container mt-4 mb-3'>
        <div className='container_detail'>
            <div className='titre_tete mb-3'>
                <h3>Détails du produit</h3>
            </div>
            <div className='body_detail_produit row'>
                <div className='image_detail col-lg-6 col-md-6 p-4'>
                    <img src={`${lien}/images/produits/${image1}`} alt={post_produit.image1} />
                </div>
                <div className='text_detail col-lg-6 col-md-6'>
                    <div className='text_detail1'>
                        <h3 className='mb-3'>Nom du produit : {post_produit.libelle}</h3>
                        <label className='mb-3'>Catégorie : {post_produit.Categorie ? post_produit.Categorie.libelle_categorie : 'Catégorie non définie'}</label> <br/>
                        <label className='mb-3'>Provenance : {post_produit.provenance}</label>
                        <h3 className='mb-0'>Déscription : </h3>
                        <div className='contenu_desc_detail'>
                            <p>{post_produit.description}</p>
                        </div>
                        <div className='mb-2'><Rating id_produit={post_produit.id_produit}/></div>
                        <label>Prix : {post_produit.prix} Ar</label> <br/>
                        <Btn_panier_detail donnee={post_produit} ajout_panier={ajout_panier} />
                    </div>
                </div>
                <div className='footer_detail row mt-5 mb-5'>
                    <Swiper
                        modules={[
                        Navigation,
                        Pagination,
                        Scrollbar,
                        A11y,
                        EffectCube,
                        Autoplay,
                        ]}
                        spaceBetween={50}
                        navigation
                        autoplay={true}
                        // scrollbar={{ draggable: true }}
                        slidesPerView={4}
                        breakpoints={{
                            1300: { slidesPerView: 4 }, 
                            1000: { slidesPerView: 3 },
                            820: { slidesPerView: 3 }, 
                            992: { slidesPerView: 2 }, 
                            520: { slidesPerView: 2 }, 
                            400: { slidesPerView: 1 } ,
                            350: {slidesPerView:1},
                            10: {slidesPerView:1}
                        }}
                    >
                        {images.map((item, id) => (
                        <SwiperSlide key={id} className="" align="center">
                            <div className='col-lg-3 col-md-4 col-sm-6 div'>
                                <img src={`${lien}/images/produits/${item.image}`} alt={item.image}/>
                            </div>
                        </SwiperSlide>
                    ))}
                    </Swiper>
                </div>
            </div>
        </div>
        </div>
        <Produit_populaire/>
        <Footer/>
    </>
  )
}
