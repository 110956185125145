import React from 'react'
import "../css/Sidebar.css"
import Cookies from 'universal-cookie'
import { NavLink, useNavigate } from 'react-router-dom'

export default function Navbar() {
  const cookies=new Cookies()
  const navigate = useNavigate()
  const deconnecte = ()=>{
  cookies.remove('id_front')
  cookies.remove('role_front')
  // navigate('/login')
  window.location.href = '/login'
}
  return (
    <>
      <div className='container_navbar_admin'>
        <a href='/' className="lien_span text-black"><div>Voir la page d'acceuil</div></a>
        <div onClick={deconnecte}>
            <i className='fa fa-sign-out'/>
        </div>
      </div>
    </>
  )
}
