import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import "../css/Login.css";
import url from '../../../url';
import Cookies from 'universal-cookie';

export default function Login() {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [email, setEmail] = useState('');
    const [mot_passe, setMot_passe] = useState('');
    const [erreur, setErreur] = useState({});
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = {};
    const [message, setMessage] = useState(false);
    const [message_confirmation, setMessage_confirmation] = useState(false);
    const [rep_message, setRep_message] = useState('');

    // useEffect(()=>{
    //     connect()
    // })

    const connect = () => {
        if (!email.trim()) {
            newErrors.email = "Le email est requis.";
        }
        if (!emailPattern.test(email)) {
            newErrors.email = "L'email est incorrect.";
        }
        if (!mot_passe.trim()) {
            newErrors.mot_passe = "Le mot de passe est requis.";
        }

        if (Object.keys(newErrors).length > 0) {
            setErreur(newErrors);
        } else {
            url.post("/inscription/login", {
                email,
                mot_passe
            })
            .then(response => {
                console.log("Enregistrement utilisateur réussi:", response.data);

                if (response.data.role === "client") {
                    if(response.data.validation===1){
                        cookies.set('id_front', response.data.id_utilisateur, { maxAge: 3600 * 2 });
                        cookies.set('role_front', response.data.role, { maxAge: 3600 * 2 });
                        setRep_message("Vous etes maintenant connecte");
                        setMessage_confirmation(true)
                        setTimeout(()=>{
                            setMessage_confirmation(false)
                            window.location.href = '/'
                        }, 1000)
                    }else{
                        setRep_message("Votre compte n'est pas valide, reesaie plutart");
                        setMessage(true);
                        setTimeout(() => {
                            setMessage(false);
                        }, 1500);
                    }
                } else if (response.data.role === "fournisseur") {
                    if(response.data.validation===1){
                        cookies.set('id_front', response.data.id_utilisateur, { maxAge: 3600 * 2 });
                        cookies.set('role_front', response.data.role, { maxAge: 3600 * 2 });
                        setRep_message("Vous etes maintenant connecte");
                        setMessage_confirmation(true)
                        setTimeout(()=>{
                            setMessage_confirmation(false)
                            window.location.href = '/profil_fournisseur'
                        }, 1000)
                    }else{
                        setRep_message("Votre compte n'est pas valide, reesaie plutart");
                        setMessage(true);
                        setTimeout(() => {
                            setMessage(false);
                        }, 1000);
                    }
                } else if (response.data.role === "admin") {
                    cookies.set('id_front', response.data.id_utilisateur, { maxAge: 3600 * 2 });
                    cookies.set('role_front', response.data.role, { maxAge: 3600 * 2 });
                    setRep_message("Vous etes maintenant connecte");
                    setMessage_confirmation(true)
                        setTimeout(()=>{
                            setMessage_confirmation(false)
                            window.location.href = '/gestion_statistique'
                        }, 1500)
                } else {
                    navigate('/login');
                    setRep_message("Le mot de passe ou l'email sont incorrect");
                    setMessage(true);
                    setTimeout(() => {
                        setMessage(false);
                    }, 1500);
                }
                // setEmail('');
                // setMot_passe('');
            });
        }
    }

    return (
        <>
            {message && <div className='erreur'>{rep_message}</div>}
            {message_confirmation && <div className='message_alert'>{rep_message}</div>}
            <div className='container container_login'>
                <div className='row mt-3'>
                    <div className='col-lg-4 col-md-4 container1_login'>
                        <img src='images/img_login.png' alt='image login' />
                    </div>
                    <div className='col-lg-8 col-md-8'>
                        <div className='containair_input_login'>
                            <div align="center" className='icone_profils mb-3'>
                                <div>
                                    <i className='fa fa-user user' />
                                </div>
                            </div>
                            <h3 align="center">Se connecter</h3>
                            <div className=''>
                                <div className='cont_inputs'>
                                    <label>Email :</label> <br />
                                    <input
                                        type='email'
                                        placeholder='email...'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {erreur.email && <p className="error_message">{erreur.email}</p>}
                                </div>
                                <div className='cont_inputs'>
                                    <label>Mot de passe :</label> <br />
                                    <input
                                        type='password'
                                        placeholder='Mot de passe...'
                                        value={mot_passe}
                                        onChange={(e) => setMot_passe(e.target.value)}
                                    />
                                    {erreur.mot_passe && <p className="error_message">{erreur.mot_passe}</p>}
                                </div>
                            </div>
                            <button className='btn_inscrire mt-3 mb-3' onClick={connect}>Se connecter</button> <br />
                            <div className=''>
                                <div className=''>Vous n'avez pas encore un compte ? 
                                    <NavLink align="right" className="" to="/inscription">
                                        <span align="right"> Inscription client</span>
                                    </NavLink>
                                </div>
                                <div className=''>Vous n'avez pas encore un compte ? 
                                    <NavLink align="right" className="" to="/inscription_fournisseur">
                                        <span align="right"> Inscription fournisseur</span>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
