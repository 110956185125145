import React, { useState } from 'react'
import "../css/Sidebar.css"
import { NavLink } from 'react-router-dom'

export default function Sidebar({ show_sidebar_function, show_sidebars }) {
    const [lien_utilisateur, setLiens_utilisateur] = useState(false)
    const [icone, setIcone] = useState(false)

    function show() {
        setLiens_utilisateur(!lien_utilisateur)
        setIcone(!icone)
    }

    return (
        <>
            <div className={`div_fereme_sidebar ${show_sidebars ? "" : "active"}`} onClick={() => show_sidebar_function()}></div>
            <div className={`p-2 container_sidebar ${show_sidebars ? "" : "active"}`}>
                <div className='image_logo_admin'>
                    <img className={`img ${show_sidebars ? "" : "active"}`} src='images/logo_fond_blanc.jpeg' alt="Logo" />
                    <span onClick={() => show_sidebar_function()}>
                        <i className='fa fa-bars' />
                    </span>
                </div>
                <div className='container_lien_admin'>
                    <div className='titre_admin'>
                        <span>
                            <i className='fa fa-user' />
                        </span>
                        <label className={`disparu ${show_sidebars ? "" : "active"}`}>Administrateur</label>
                    </div>
                    <div className='liens1'>
                        <div className={`btn_ouvert ${!lien_utilisateur ? "" : "active"}`} onClick={() => show()}>
                            <span>
                                {/* <i className='fa fa-user' /> */}
                                <img src='images/icone/customer.png' className='icones_side' />
                                <label className={`disparu ${show_sidebars ? "" : "active"}`}>Utilisateur</label>
                            </span>
                            <span>
                                {!lien_utilisateur && <i className="fas fa-caret-right" />}
                                {lien_utilisateur && <i className="fas fa-caret-down seconde_child" />}
                            </span>
                        </div>
                        {lien_utilisateur &&
                            <div className='btn_liens'>
                                <a href='/gestion_client' className="text-black text-decoration-none">
                                    <div className='{`btn_click ${lien_utilisateur ? "" : "active"}`}'>
                                        <img src='images/icone/user.png' className='icones_side' />
                                        <label className={`disparu ${show_sidebars ? "" : "active"}`}>Client</label>
                                    </div>
                                </a>
                                <a href='/gestion_fournisseur' className="text-black text-decoration-none">
                                    <div>
                                        <img src='images/icone/fournisseur.png' className='icones_side' />
                                        <label className={`disparu ${show_sidebars ? "" : "active"}`}>Fournisseur</label>
                                    </div>
                                </a>
                            </div>
                        }
                    </div>
                    <div className='liens1'>
                        <a href='/gestion_produit' className="text-black text-decoration-none">
                            <div className='btn_ouvert'>
                                <span>
                                    <img src='images/icone/produit.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Produit</label>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className='liens1'>
                        <a href='/gestion_commande' className="text-black text-decoration-none">
                            <div className='btn_ouvert'>
                                <span>
                                    <img src='images/icone/commande.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Commande</label>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className='liens1'>
                        <a href='/gestion_publicite' className="text-black text-decoration-none">
                            <div className='btn_ouvert'>
                                <span>
                                    <img src='images/icone/publicit.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Publicité</label>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className='liens1'>
                        <a href='/gestion_statistique' className="text-black text-decoration-none">
                            <div className='btn_ouvert'>
                                <span>
                                    <img src='images/icone/chart.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Statistique</label>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className='liens1'>
                        <a href='/gestion_categorie' className="text-black text-decoration-none">
                            <div className='btn_ouvert'>
                                <span>
                                    <img src='images/icone/categories.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Catégorie</label>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className='liens1'>
                        <a href='/gestion_unite' className="text-black text-decoration-none">
                            <div className='btn_ouvert'>
                                <span>
                                    <img src='images/icone/mesure.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Unité</label>
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
