import React, { useState } from 'react'
import Navbar from '../Layout/js/Navbar'
import Sidebar from '../Layout/js/Sidebar'
import "../Client/css/Gestion_client.css"
import Categorie from './Categorie'

export default function Gestion_categorie() {
  
  const [show_sidebars, setShow_sidebars]=useState(true)

  function show_sidebar_function(){
    setShow_sidebars(!show_sidebars)
  }
  return (
    <>
      <Navbar show_sidebars={show_sidebars}/>
      <Sidebar show_sidebar_function={show_sidebar_function} show_sidebars={show_sidebars}/>
      <div className={`container_body m-2 ${show_sidebars ? "" : "active"}`}>
        <Categorie/>
      </div>
    </>
  )
}
