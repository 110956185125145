import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import "../css/Inscription.css"
import url from '../../../url'

export default function Inscription() {
    const navigate = useNavigate()
    const [nom, setNom]=useState('')
    const [prenom, setPrenom]=useState('')
    const [cin, setCin]=useState('')
    const [telephone, setTelephone]=useState('')
    const [sexe, setSexe]=useState('')
    const [date_naissance, setDate_naissance]=useState('')
    const [age, setAge]=useState('')
    const [adresse, setAdresse]=useState('')
    const [email, setEmail]=useState('')
    const [mot_passe, setMot_passe]=useState('')
    const [confirm_mot_passe, setConfirm_mot_passe]=useState('')
    const [erreur, setErreur]=useState({})
    const [message, setMessage]=useState(false)
    const [message_confirmation, setMessage_confirmation]=useState(false)
    const [rep_message, setRep_message]=useState('')
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors={}

    const handSexe = (e) => {
        const { name, value } = e.target;
        setSexe({ ...sexe, [name]: value });
    };

    const inscrire = ()=>{
        if(!nom.trim()){
            newErrors.nom = "Le nom est requis."
        }
        if(!prenom.trim()){
            newErrors.prenom = "Le prénom est requis."
        }
        // if(!cin.trim()){
        //     newErrors.cin = "Le CIN est requis."
        // }
        if(!telephone.trim()){
            newErrors.telephone = "Le téléphone est requis."
        }
        if(!sexe.trim()){
            newErrors.sexe = "Le sexe est requis."
        }
        if(!date_naissance.trim()){
            newErrors.date_naissance = "Le sexe est requis."
        }
        if(!age.trim()){
            newErrors.age = "L'age est requis."
        }
        if(!adresse.trim()){
            newErrors.adresse = "L'adresse est requis."
        }
        if(!email.trim()){
            newErrors.email = "L'e-mail est requis."
        }
        if (!emailPattern.test(email)) {
            newErrors.email = "L'e-mail est incorrect.";
        }
        if(!mot_passe.trim()){
            newErrors.mot_passe = "Le mot de passe est requis."
        }
        if(!confirm_mot_passe.trim()){
            newErrors.confirm_mot_passe = "Le confirmation de mot de passe de  est requis."
        }
        if(mot_passe!==confirm_mot_passe){
            newErrors.confirm_mot_passe = "La confirmation de mot de passe est incorrect"
        }

        if(Object.keys(newErrors).length>0){
            setErreur(newErrors)
        }else{
            url.post("/inscription/ajout_utilisateur", {
                email,
                mot_passe,
                role: "client",
                validation:1
            })
            .then(response => {
                console.log("Enregistrement utilisateur réussi:", response.data);
                setRep_message(response.data.message)
                setMessage(true);
                    setTimeout(() => {
                        setMessage(false);
                }, 1500);
                const id_utilisateur = response.data.id_utilisateur;
                url.post(`/inscription/ajout_client`, {
                    nom, prenom, cin, telephone, sexe, age, adresse, date_naissance, id_utilisateur
                })
                .then(response => {
                    console.log("Resultat de l'ajout : ", response.data);
                    setNom('')
                    setPrenom('')
                    setCin('')
                    setTelephone('')
                    setSexe('')
                    setDate_naissance('')
                    setAge('')
                    setAdresse('')
                    setEmail('')
                    setMot_passe('')
                    setConfirm_mot_passe('')
                    setRep_message("Votre compte a été enregistre !")
                    setMessage_confirmation(true)
                    setTimeout(()=>{
                        setMessage_confirmation(false)
                        navigate('/login')
                    }, 1500)
                })
                .catch(error => {
                    console.error("Erreur lors de l'ajout d'entreprise:", error);
                });
            })
            .catch(error => {
                console.error("Erreur lors de l'enregistrement de l'utilisateur:", error);
            });
        }
    }
  return (
    <>
    {message && <div className='message_alert'>{rep_message}</div>}
    {message_confirmation && <div className='message_alert'>{rep_message}</div>}
      <div className='container'>
        <div className='row mr-2 ml-2 mt-5 mb-5'>
            <div className='col-lg-8 col-md-9'>
            <h3 align="center">Inscription client</h3>
                <div className=''>
                    <div className='row inscription_input'>
                        <div className='col-lg-6 col-md-6'>
                            <div>
                                <div className='cont_input'>
                                    <label>Nom :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Nom...'
                                    value={nom}
                                    onChange={(e)=>setNom(e.target.value)}
                                    />
                                    {erreur.nom && 
                                    <p className="error_message">{erreur.nom}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Prénom :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Prénom...'
                                    value={prenom}
                                    onChange={(e)=>setPrenom(e.target.value)}
                                    />
                                    {erreur.prenom && 
                                    <p className="error_message">{erreur.prenom}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Date de naissance :</label> <br/>
                                    <input 
                                    type='date' 
                                    placeholder='Date...'
                                    value={date_naissance}
                                    onChange={(e)=>setDate_naissance(e.target.value)}
                                    />
                                    {erreur.date_naissance && 
                                    <p className="error_message">{erreur.date_naissance}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>CIN :</label> <br/>
                                    <input 
                                    type='number' 
                                    placeholder='CIN...'
                                    value={cin}
                                    onChange={(e)=>setCin(e.target.value)}
                                    />
                                    {erreur.cin && 
                                    <p className="error_message">{erreur.cin}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Téléphone :</label> <br/>
                                    <input 
                                    type='number' 
                                    placeholder='Téléphone...'
                                    value={telephone}
                                    onChange={(e)=>setTelephone(e.target.value)}
                                    />
                                    {erreur.telephone && 
                                    <p className="error_message">{erreur.telephone}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Age :</label> <br/>
                                    <input 
                                    type='number' 
                                    placeholder='Age...'
                                    value={age}
                                    onChange={(e)=>setAge(e.target.value)}
                                    />
                                    {erreur.age && 
                                    <p className="error_message">{erreur.age}</p>}
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6'>
                            <div>
                                <div className='cont_input input_sexe'>
                                    <span><label className='text-nowrap'>Sexe :</label></span>
                                    <span className="input_radio_group">
                                        <span>
                                            <label for="homme">Homme</label>
                                            <input
                                                className='input_type_radio'
                                                name="sexe"
                                                onChange={handSexe}
                                                value="homme"
                                                id="homme"
                                                required
                                                type="radio"
                                            />
                                        </span>
                                        <span>
                                            <label for="femme">Femme</label>
                                            <input
                                                className='input_type_radio'
                                                name="sexe"
                                                value="femme"
                                                onChange={handSexe}
                                                id="femme"
                                                required
                                                type="radio"
                                            />
                                        </span>
                                        {erreur.sexe && 
                                        <p className="error_message">{erreur.sexe}</p>}
                                    </span>
                                </div>
                                
                                <div className='cont_input'>
                                    <label>Adresse :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Adresse...'
                                    value={adresse}
                                    onChange={(e)=>setAdresse(e.target.value)}
                                    />
                                    {erreur.adresse && 
                                    <p className="error_message">{erreur.adresse}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>E-mail :</label> <br/>
                                    <input 
                                    type='email' 
                                    placeholder='E-mail...'
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    />
                                    {erreur.email && 
                                    <p className="error_message">{erreur.email}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Mot de passe :</label> <br/>
                                    <input 
                                    type='password' 
                                    placeholder='Mot de passe...'
                                    value={mot_passe}
                                    onChange={(e)=>setMot_passe(e.target.value)}
                                    />
                                    {erreur.mot_passe && 
                                    <p className="error_message">{erreur.mot_passe}</p>}
                                </div>
                                <div className='cont_input sexe_bas'>
                                    <label>Confirmer le mot de passe :</label> <br/>
                                    <input 
                                    type='password' 
                                    placeholder='Confirmer le mot de passe...'
                                    value={confirm_mot_passe}
                                    onChange={(e)=>setConfirm_mot_passe(e.target.value)}
                                    />
                                    {erreur.confirm_mot_passe && 
                                    <p className="error_message">{erreur.confirm_mot_passe}</p>}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <button className='btn_inscrire mb-3' onClick={inscrire}>S'inscrire</button> <br/>
                <NavLink className="" to="/login">
                    <span className='lien_connecter'>Se connecter</span>
                </NavLink>
            </div>
            <div className='image_insciption col-lg-4 col-md-3'>
                <img src='images/img_inscription.png' alt='inscription'/>
            </div>
        </div>
      </div>
    </>
  )
}
