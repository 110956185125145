import React, { useEffect, useState } from 'react'
import "../css/Apropos.css"
import Navbar from '../../Layout/js/Navbar'
import Footer from '../../Layout/js/Footer'

export default function Apropos() {
    //Panier
  const [list_produit, setListe_produit]=useState([])
  const [isErreur, setIseErreur]=useState(false)
  const storedListProduit = sessionStorage.getItem('list_produit');
  useEffect(() => {
      if (storedListProduit) {
          const parsedListProduit = JSON.parse(storedListProduit);
          setListe_produit(parsedListProduit);
      }
  }, []);
  return (
    <>
    <Navbar size={list_produit.length}/>
      <div className='container container_apropos mt-3'>
      <div className='sous_ecran'>
        <div className='titre' align="center">
            <h3>A propos de nous</h3>
        </div>
        <div className='text1'>
            <p>
                La plateforme e-tantsaha incarne une vision novatrice dans le monde du commerce 
                électronique en visant à établir un lien direct entre les agriculteurs et les 
                consommateurs. En déployant une approche révolutionnaire, elle s'attaque au défi des 
                intermédiaires traditionnels tels que les grossistes et les détaillants, dans le but 
                ultime de garantir une qualité de produit supérieure et une juste rémunération pour 
                les travailleurs de la terre.
            </p>
        </div>
        <div className='block2 row mb-3'>
            <div className='block2_img col-lg-6 col-md-6 mb-3'>
                <img src='images/apropos1.jpg'/>
            </div>
            <div className='block2_text col-lg-6 col-md-6'>
                <p>
                    Le cœur de cette plateforme repose sur la simplification des échanges commerciaux 
                    à travers des transactions en ligne, tout en offrant une gamme complète de 
                    fonctionnalités de gestion des commandes et de paiement sécurisé. Cette conception 
                    innovante vise à améliorer l'expérience globale pour les administrateurs qui gèrent 
                    la plateforme et les consommateurs qui utilisent ses services.
                    L'objectif sous-jacent est double. D'une part, la plateforme s'engage à préserver 
                    et à élever la qualité des produits en rapprochant directement les agriculteurs et 
                    les consommateurs. Cette connexion directe permet non seulement de garantir la 
                    traçabilité et la fraîcheur des produits, mais également de promouvoir une 
                    transparence accrue dans la chaîne d'approvisionnement.
                </p>
            </div>
        </div>
        <div className='block3 row mb-3'>
            <div className='col-lg-6 col-md-6'>
                <p>
                    D'autre part, cette plateforme place fermement la rémunération équitable des 
                    agriculteurs au centre de sa mission. En supprimant les couches intermédiaires, 
                    elle s'efforce de garantir que les bénéfices de la vente parviennent directement 
                    aux producteurs, contribuant ainsi à améliorer leur qualité de vie et leur capacité 
                    à investir dans des pratiques agricoles durables.
                    L'interface utilisateur conviviale pour les administrateurs et les clients renforce 
                    la praticité de la plateforme. Les clients peuvent filtrer les produits en fonction 
                    de leurs préférences, s'inscrire ou se connecter pour passer des commandes, choisir 
                    l'origine et le lieu de livraison, ainsi que sélectionner leur mode de paiement. 
                    De même, les agriculteurs professionnels peuvent gérer leurs commandes, coopérer 
                    au sein de coopératives et être assurés d'une rémunération équitable une fois la 
                    livraison effectuée.
                </p>
            </div>
            <div className='block3_img col-lg-6 col-md-6'>
                <img src='images/apropos2.jpeg' alt='image a propos'/>
            </div>
        </div>
        <div className='block4'>
            <p>
                En fin de compte, e-tantsaha démontre comment la technologie peut être au service de 
                l'équité et de l'efficacité économique. En offrant une alternative moderne au modèle 
                commercial traditionnel, la plateforme façonne un avenir où les agriculteurs et les 
                consommateurs sont plus proches que jamais, créant ainsi des opportunités de croissance 
                et de développement durable pour toutes les parties impliquées.
            </p>
        </div>
      </div>
      </div>
      <Footer/>
    </>
  )
}