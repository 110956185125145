import React, { useState } from 'react'
import "../css/Footer.css"

export default function Footer() {
    const [isereur, setIseErreur]=useState(false)
    const handleClick = ()=>{
        setIseErreur(true)
        setTimeout(()=>{
            setIseErreur(false)
        }, 1500)
    }
  return (
    <>
    {
        isereur && <div className='message_erreur'>En cours de développement</div>
    }
      <div className='container_footer'>
        <div className='sous_ecran'>
        <div className='container'>
            <div className='container_footer1 row justify-center'>
                <div className='logo  col-md-6 col-lg-3 div mb-4'>
                    <img className='logolong' id='logolong' src='images/logo_fond_vert.jpeg'/>
                </div>
                <div className='service col-md-6 col-lg-4 div mb-4 '>
                    <div className='sous_service'>
                        <p>Liens</p>
                        <a href='/'>Acceuil</a>
                        <a href='/shop'>Shop</a>
                        <a href='/apropos'>A propos</a>
                        <a href='/contact'>Contact</a>
                    </div>
                </div>
                {/* <div className='ressource col-md-6 col-lg-4 mr-0 div mb-4 justify-center'>
                    <div className='sous_ressource'>
                        <p>Divers</p>
                        <span onClick={()=>handleClick()} className='justify-center' href='#'>Ressources Utiles</span>
                        <span onClick={()=>handleClick()} href='#'>Actualités Locales</span>
                        <span onClick={()=>handleClick()} href='#'>Événements Inspirants</span>
                        <span onClick={()=>handleClick()} href='#'>Partage de Connaissances </span>
                        <span onClick={()=>handleClick()} href='#'>Accès Pratique</span>
                    </div>
                </div> */}
                <div className='contact col-md-6 col-lg-4 div mb-4'>
                    <div>
                        <p>Coordonnées</p>
                        <span className='mail gap-1'><i className='fa fa-envelope'/> info@etantsaha.com</span>
                        <span className='phone gap-1'>
                            <i className='fa fa-phone'/> 
                            <span>
                            	+261 33 70 879 64 <br/> +261 38 98 754 72
                            </span>
                        </span>
                        <span className='lieu'>
                            <span className='icone_lieu'><i className='fa fa-map-marker'/></span>
                            <span className='text_lieu'>Lot VK 17A Fenomanana-Mahazoarivo <br/>Antananarivo-101 <br/>Madagascar</span>
                        </span>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='container_footer2 row' align="center">
                <div className='copyright'>
                <p>ⓒ Copyright 
                    <a className='lien_span' href='https://www.haisoa.com' target='_blank'> HAISOA </a>
                    . Tous droits réservés, conçu par 
                    <a className='lien_span' href='https://www.haisoa.com' target='_blank'> HAISOA.</a>
                </p>

                </div>
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
