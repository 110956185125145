import React, { useEffect, useState } from 'react'
import url from '../../../url';
import Rating from '../../Shop/js/Rating';
import { NavLink } from 'react-router-dom'
import lien from '../../../url_image';

export default function Produit_populaire() {
    //Affichage et pagination
  const [post_produit, setPost_produit]=useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const recordPage = 4;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const records = post_produit.slice(firstIndex, lastIndex);
  const npage = Math.ceil(post_produit.length / recordPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  //Affiche tous les produits
  useEffect(()=>{
      url.get("/produit/tous_produit").then((response)=>{
        setPost_produit(response.data)
    }).catch((error)=>{
        console.log(error)
    })
  }, [])

  //Panier
const [list_produit, setListe_produit]=useState([])
const [isErreur, setIseErreur]=useState(false)
  const storedListProduit = sessionStorage.getItem('list_produit');
  useEffect(() => {
      if (storedListProduit) {
          const parsedListProduit = JSON.parse(storedListProduit);
          setListe_produit(parsedListProduit);
      }
  }, []);

  //Affichage de handle leave et handle hover
  const [visible_info, setVisible_info] = useState({});
  function handle_hover(id) {
      setVisible_info((prevVisible_info) => ({
      ...prevVisible_info,
      [id]: true,
    }));
  }
  function handle_leave(id) {
      setVisible_info((prevVisible_info) => ({
      ...prevVisible_info,
      [id]: false,
  }));
  }
  function classVisible(id) {
  return `info_shop ${visible_info[id] ? 'visible' : ''}`;
  }

  //Ajout de panier
  const ajout_panier = (item) => {
    let isExist = false;
  
    const storedListProduit = sessionStorage.getItem('list_produit');
    if (storedListProduit) {
      const parsedListProduit = JSON.parse(storedListProduit);
      
      for (const produit of parsedListProduit) {
        if (item.id_produit === produit.id_produit) {
          isExist = true;
          setIseErreur(true);
          setTimeout(() => {
            setIseErreur(false);
          }, 1500);
          break; // Sortir de la boucle dès qu'on trouve un produit existant
        }
      }
    }
    if (!isExist) {
      setListe_produit([...list_produit, item]);
      sessionStorage.setItem('list_produit', JSON.stringify([...list_produit, item]));
    }
  };
  return (
    <>
    {
      isErreur && <div className='erreur'>Le produit existe dejat dans le panier</div>
    }
        <div className='container'>
            <h3 className='m-0' align="center">Produits populaires</h3>
            <div className='shop row mb-5 mt-4 p-3' align="center">
                {records.map((val, id)=>(
                    <div key={id} className='p-0 col-lg-3 col-md-4 col-sm-6 
                    d-flex align-items-center justify-content-center'
                    onMouseEnter={()=>handle_hover(val.id_produit)}
                    onMouseLeave={()=>handle_leave(val.id_produit)}
                    >
                    <div align="center" className='container_shop m-2'>
                        <div className='container_photo_shop'>
                            <div className='photo_shop'>
                              <img src={`${lien}/images/produits/${val.image1}`} alt={val.image1} />
                            </div>
                            <div className={classVisible(val.id_produit)} id='rating_id' align="center">
                                <div className='info_shop_icone'>
                                <NavLink className='lien_span' 
                                to={{
                                    pathname: "/detail_produit",
                                    search: `?nom=${val.libelle}&provenance=${val.provenance}&description=${val.description}&prix=${val.prix}`,
                                    state: {val, ajout_panier }
                                }}
                                >
                                    <span>
                                        <i className='fa fa-eye icone'/>
                                    </span>
                                </NavLink>
                                    <i onClick={()=>ajout_panier(val)} className='fa fa-shopping-cart icone'/>
                                </div>
                                <div className='info_shop_star'>
                                    <Rating id_produit={val.id_produit}/>
                                </div>
                            </div>
                        </div>
                        <div className='detail_shop' align="center">
                            <h4>{val.libelle}</h4>
                            <p>{val.prix} Ar/kg</p>
                            <label>{val.provenance}</label>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}
