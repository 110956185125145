import React from 'react';

export default function Btn_panier_detail({ donnee, ajout_panier }) {
  return (
    <div>
        <button onClick={() => ajout_panier(donnee)}>Ajouter au panier 
        <i className='fa fa-shopping-cart'/></button>
    </div>
  );
}
