import React, { useEffect, useState } from 'react'
import Navbar from '../../Layout/js/Navbar'
import Footer from '../../Layout/js/Footer'
import { NavLink, useNavigate } from 'react-router-dom'
import "../css/Payement.css"
import url from '../../../url';
import Cookies from 'universal-cookie'

export default function Payement() {
  //securisation
  const cookies=new Cookies()
  const [id_cookie, setId_cookie] = useState('');
  const [role_cookie, setRole_cookie] = useState();
  useEffect(() => {
      const idFromCookie = cookies.get('id_front');
      const roleFromCookie = cookies.get('role_front');
      setId_cookie(idFromCookie);
      setRole_cookie(roleFromCookie);
  }, [cookies]);
//securisation

  const [ajout_autre_lieu, setAjout_autre_lieu]=useState(false)
  const [autre_lieu, setAutre_lieu]=useState('')
  let vrai_lieu_livrason =''
  const navigate = useNavigate()
  const [lieu_livraison, setLieu_livrason]=useState('')
  const [choix_livreur, setChoix_livreur]=useState('')
  const [reference, setReference]=useState('')
  const [mode_payement, setMode_payement] = useState({ mode_payement: '' });
  const [mode_payement_choix, setMode_payement_choix] = useState(true);
  const [erreur, setErreur] = useState({ mode_payement: '' });

  const handleModePayementChange = (e) => {
    const { name, value } = e.target;
    setMode_payement({ ...mode_payement, [name]: value });
    
    if (value === "enligne") {
      setMode_payement_choix(true);
    } else {
      setMode_payement_choix(false);
    }
    setErreur({ ...erreur, mode_payement: '' });
  };

  if(autre_lieu===''){
    vrai_lieu_livrason=lieu_livraison
  }else{
    vrai_lieu_livrason=autre_lieu
  }

  const change_lieu_livrason = (e)=>{
      const selectedMode = e.target.value;
      setLieu_livrason(selectedMode)
  }
  const change_choix_livreur = (e)=>{
      const selectedMode = e.target.value;
      setChoix_livreur(selectedMode)
  }

    //affiche le produit dans le panier
    const storedListProduit = sessionStorage.getItem('list_produit');
    const [list_produits, setListe_produit] = useState([]);
    useEffect(() => {
        if (storedListProduit) {
            const parsedListProduit = JSON.parse(storedListProduit);
            setListe_produit(parsedListProduit);
        }
    }, []);

     //total de prix
     const [total_prix, setTotal_prix]=useState(0)
     const prix_totals=()=>{
         let total=0
         list_produits.map((item)=>{
             total +=item.prix * item.quantite
         })
         setTotal_prix(total)
     }
     useEffect(()=>{
        prix_totals()
     })

    const termine_commende = async () => {
      // alert()
        try {
          if (!choix_livreur.trim()) {
            throw new Error("Le livreur est requis.");
          }
          if (!vrai_lieu_livrason.trim()) {
            throw new Error("Le lieu de livraison est requis.");
          }
          const motif = "Peyer le commande";

          const paymentResponse = await url.post("/commande/ajout_payement", {
            motif,
            mode_payement:mode_payement.mode_payement,
            total_prix,
            id_cookie,
            reference
          });
          const id_payement = paymentResponse.data.id_payement;
          const orderResponse = await url.post("/commande/ajout_commande", {
            lieu_livraison:vrai_lieu_livrason,
            choix_livreur,
            id_payement,
            id_cookie
          });
      
          const id_commande = orderResponse.data.id_commande;
      
          const detailPromises = list_produits.map(async (item) => {
            try {
              const detailResponse = await url.post("/commande/ajout_detail_commande", {
                id_produit: item.id_produit,
                quantite: item.quantite,
                provenance:item.provenance,
                id_commande
              });
              return detailResponse;
            } catch (detailError) {
              // Handle error for individual detail request
              console.error("Error adding detail command:", detailError);
              throw detailError;
            }
          });
          const detailResponses = await Promise.all(detailPromises);
      
          console.log("Resultat de l'ajout : ", detailResponses);
      
          setListe_produit([]);
          sessionStorage.removeItem('list_produit');
          navigate('/shop');
        } catch (error) {
          console.error("An error occurred:", error);
          setErreur(error.message);
        }
    };

    function func_ajout_autre_lieu(){
      setAjout_autre_lieu(!ajout_autre_lieu)
    }
  return (
    <>
    <Navbar/>
      <div className='container container_payment mt-3 p-3'>
      <h4 align="center" className='mb-3'>Récapitulation</h4>
        <div className='row'>
            <div className='col-lg-6 col-md-6'>
                <div className='container1_paiment'>
                    <span className='titre_lieu_livraison'>
                        <label>Lieu de livraison :</label>
                        <label className='ajout_liv'
                        onClick={()=>func_ajout_autre_lieu()}
                        >Ajouter un lieu de livraison</label>
                    </span> <br/>
                    <select value={lieu_livraison} onChange={change_lieu_livrason}>
                        <option value="">- Choisir un lieu -</option>
                        <option value="Ambohipo">Ambohipo</option>
                        <option value="Ambendra">Ambendra</option>
                    </select>
                    {erreur.lieu_livraison && 
                    <p className="error_message">{erreur.lieu_livraison}</p>}
                </div>
                {ajout_autre_lieu &&
                <div className='cont_input mt-2'>
                    <input 
                    type='text' 
                    placeholder='Autre lieu...'
                    value={autre_lieu}
                    onChange={(e)=>setAutre_lieu(e.target.value)}
                    />
                </div>}
                <div className='mt-3'>
                    <label>Livreur :</label> <br/>
                    <select value={choix_livreur} onChange={change_choix_livreur}>
                        <option value="">Choisir un livreur</option>
                        <option value="Bema">Bema</option>
                        <option value="Sara">Sara</option>
                    </select>
                    {erreur.choix_livreur && 
                    <p className="error_message">{erreur.choix_livreur}</p>}
                </div>
            </div>
            <div className='col-lg-6 col-md-6'>
                <div className='container2_payement'>
                    <div className='mb-3 mt-3 input_radio_pay'>
                        <h5 className='mb-0'>Choix de paiement :</h5>
                        <span className="input_radio_group">
                              <span>
                                <label className='text-nowrap' for="enligne">Payer par mobile money : </label>
                                <input
                                  className='input_type_radio'
                                  name="mode_payement"
                                  onChange={handleModePayementChange}
                                  value="enligne"
                                  id="enligne"
                                  required
                                  type="radio"
                                />
                              </span>
                              <span>
                                <label className='text-nowrap' htmlFor="livraison">Payer au moment de la livraison : </label>
                                <input
                                  className='input_type_radio'
                                  name="mode_payement"
                                  value="livraison"
                                  onChange={handleModePayementChange}
                                  id="livraison"
                                  required
                                  type="radio"
                                />
                              </span>
                            {erreur.mode_payement && 
                            <p className="error_message">{erreur.mode_payement}</p>}
                        </span>
                    </div>
                    {mode_payement_choix &&
                    <div>
                        <label>Payer votre commande en envoyant le total au numéro 034 81 818 21. <br/>
                        Une fois votre paiment terminé, veuillez entrer le référence de
                        votre transaction dans le champ ci-dessous</label> <br/>
                        <input 
                        value={reference}
                        onChange={(e)=>setReference(e.target.value)} 
                        type='text'
                        placeholder='Saisissé le référence ici...'/>
                    </div>}
                    <button className='btn_inscrire mt-2' onClick={termine_commende}>Terminer</button>
                </div>
            </div>
        </div>
      </div>
    <Footer/>
    </>
  )
}
