import React, { useEffect, useState } from 'react'
import Navbar from '../../Layout/js/Navbar'
import Footer from '../../Layout/js/Footer'
import Rating from './Rating'
import "../css/Shop.css"
import { NavLink } from 'react-router-dom'
import url from '../../../url'
import lien from '../../../url_image'
import Btn_panier from './btn_panier'

export default function Shop() {
  //Affichage et pagination
  const [post_produit, setPost_produit]=useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const recordPage = 16;
  const lastIndex = currentPage * recordPage;
  const firstIndex = lastIndex - recordPage;
  const records = post_produit.slice(firstIndex, lastIndex);
  const npage = Math.ceil(post_produit.length / recordPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  //Affiche tous les produits
  useEffect(()=>{
      url.get("/produit/tous_produit").then((response)=>{
        setPost_produit(response.data)
    }).catch((error)=>{
        console.log(error)
    })
  }, [])

  //Affiche categorie
  const [categorie, setCategorie]=useState([])
   const Load = ()=>{
      url.get("/categorie/affiche").then((response)=>{
          setCategorie(response.data)
      }).catch((error)=>{
          console.log(error)
      })
    }
  useEffect(()=>{
    (async()=> await Load())()
  }, [])

  //Recherche
  const [option_categorie, setOption_categorie]=useState('')
  const [recherche, setRecherche]=useState('')
  const handle_recherche = (e)=>{
    let value = e.target.value
    setRecherche(value)
  }
  const handle_categorie = (e)=>{
    let value = e.target.value
    setOption_categorie(value)
  }

    function prePage() {
      if (currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      }
    }
    function nextPage() {
      if (currentPage !== npage) {
        setCurrentPage(currentPage + 1);
      }
    }
    function changePage(id) {
      setCurrentPage(id);
    }
    //Affichage et pagination

    //Affichage de handle leave et handle hover
    const [visible_info, setVisible_info] = useState({});
    function handle_hover(id) {
        setVisible_info((prevVisible_info) => ({
        ...prevVisible_info,
        [id]: true,
      }));
    }
    function handle_leave(id) {
        setVisible_info((prevVisible_info) => ({
        ...prevVisible_info,
        [id]: false,
    }));
    }
    function classVisible(id) {
    return `info_shop ${visible_info[id] ? 'visible' : ''}`;
    }

//Panier
  const [list_produit, setListe_produit]=useState([])
  const [isErreur, setIseErreur]=useState(false)
  const storedListProduit = sessionStorage.getItem('list_produit');
  useEffect(() => {
      if (storedListProduit) {
          const parsedListProduit = JSON.parse(storedListProduit);
          setListe_produit(parsedListProduit);
      }
  }, []);
    //Ajout de panier
  const ajout_panier = (item) => {
    let isExist = false;
  
    const storedListProduit = sessionStorage.getItem('list_produit');
    if (storedListProduit) {
      const parsedListProduit = JSON.parse(storedListProduit);
      
      for (const produit of parsedListProduit) {
        if (item.id_produit === produit.id_produit) {
          isExist = true;
          setIseErreur(true);
          setTimeout(() => {
            setIseErreur(false);
          }, 1500);
          break;
        }
      }
    }
    if (!isExist) {
      setListe_produit([...list_produit, item]);
      sessionStorage.setItem('list_produit', JSON.stringify([...list_produit, item]));
    }
  };

  return (
    <>
    {isErreur && <div className='erreur'>Le produit existe dejat dans le panier</div>}
    <Navbar size={list_produit.length}/>
      <div className='container'>
        <div className='tete_achat row ml-2 mr-2 mt-3'>
            {/* <div className='col-lg-3 col-md-6'>
                <div className='titre'>
                    Shop
                </div>
            </div> */}
            <div className='col-lg-5 col-md-6'>
                <div className='secteur_recherche'>
                    <div className='row'>
                        <span className='col-lg-4 col-md-4 text-nowrap'>
                            Catégorie :
                        </span>
                        <span className='col-lg-7 col-md-8'>
                            <select className='option_secteur'
                            onChange={handle_categorie}>
                                <option value="">Tous les catégories</option>
                                {categorie.map((item)=>(
                                    <option value={item.libelle_categorie}>{item.libelle_categorie}</option>
                                ))}
                            </select>
                        </span>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-12 input_recherches'>
              <div className='input_recherche1'>
                  <input type='text' placeholder='Recherche...' 
                  onChange={handle_recherche}
                  />
                  <i className='fa fa-search'/>
              </div>
            </div>
          </div>
        <div className='shop row mb-5 mt-4' align="center">
        {records.filter((val)=>{
            return val.description.toLowerCase().includes(recherche.toLowerCase()) && 
            val.Categorie.libelle_categorie.includes(option_categorie)
        }).map((val, id) => (
            <div key={id} className=' col-lg-3 col-md-4 col-sm-6 
            d-flex align-items-center justify-content-center p-0'
            onMouseEnter={()=>handle_hover(val.id_produit)}
            onMouseLeave={()=>handle_leave(val.id_produit)}
            >
            <div className='container_shop text-align-justify' align="center">
                <div className='container_photo_shop text-align-justify'>
                    <div className='photo_shop'>
                        <img src={`${lien}/images/produits/${val.image1}`} alt={val.image1} />
                    </div>
                    <div className={classVisible(val.id_produit)} id='rating_id' align="center">
                        <div className='info_shop_icone'>
                        <NavLink className='lien_span'
                        to={{
                            pathname: "/detail_produit",
                            search: `?id_produit=${val.id_produit}`
                          }}
                          >
                            <span>
                                <i className='fa fa-eye icone'/>
                            </span>
                        </NavLink>
                            <Btn_panier donnee={val} list_produit={list_produit} ajout_panier={ajout_panier} />
                        </div>
                        <div className='info_shop_star'>
                            <Rating id_produit={val.id_produit}/>
                        </div>
                    </div>
                </div>
                <div className='detail_shop' align="center">
                    <h4>{val.libelle}</h4>
                    <p>{val.prix} Ar/kg</p>
                    <label>{val.provenance}</label>
                </div>
            </div>
            </div>
            ))}
        </div>
      </div>
      <div className="pagination justify-content-center mt-2 mb-5 ">
        <button className="page-link" onClick={prePage}>
            Précédent
        </button>
        {numbers.map((item, id) => (
          <button
            className={`page-item ${
              currentPage === item ? "active" : ""
            } page-link`}
            key={id}
            onClick={() => changePage(item)}
          >
            {item}
          </button>
        ))}
        <button className="page-link" onClick={nextPage}>
            Suivant
        </button>
      </div>
      <Footer/>
    </>
  )
}
