import React, { useEffect, useRef, useState } from 'react'
import url from "../../url"
import DataTable from 'react-data-table-component'
import Cookies from 'universal-cookie';

export default function Profil() {
    //securisation
    const cookies=new Cookies()
    const [id_cookie, setId_cookie] = useState('');
    const [role_cookie, setRole_cookie] = useState();
    useEffect(() => {
        const idFromCookie = cookies.get('id_front');
        const roleFromCookie = cookies.get('role_front');
        setId_cookie(idFromCookie);
        setRole_cookie(roleFromCookie);
      }, [cookies]);
    //securisation

    //Affiche categorie
    const [affiche_agence, setAffiche_agence]=useState([])
    useEffect(()=>{
        url.get("/agence/affiche").then((response)=>{
            setAffiche_agence(response.data)
        }).catch((error)=>{
            console.log(error)
        })
    }, [])
    
    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            const response = await url.get(`/fournisseur/affiche_fournisseur_connecte/${id_cookie}`);
            setDonnee(response.data);
            setFiltre_donnee(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        if(id_cookie){
            (async()=> await Donnee())()
        }
    },[id_cookie])

    // modifier fournisseur
    const c_nom=useRef(null)
    const c_prenom=useRef(null)
    const c_telephone=useRef(null)
    const c_cin=useRef(null)
    const c_adresse=useRef(null)
    const c_sexe=useRef(null)
    const c_age=useRef(null)
    const c_email=useRef(null)
    const c_date_naissance=useRef(null)
    const c_technique_agricole=useRef(null)
    const [modal_modifie, setModal_modifie]=useState(false)

    function toggle_modal_modifie(){
        setNom(c_nom.current.textContent)
        setPrenom(c_prenom.current.textContent)
        setTelephone(c_telephone.current.textContent)
        setCin(c_cin.current.textContent)
        setAdresse(c_adresse.current.textContent)
        setSexe(c_sexe.current.textContent)
        setAge(c_age.current.textContent)
        setEmail(c_email.current.textContent)
        setDate_naissance(c_date_naissance.current.textContent)
        setTechnique_agricol(c_technique_agricole.current.textContent)
        setModal_modifie(!modal_modifie)
    }

    const [alert_message, setAlert_message]=useState(false)
    const [message, setMessage]=useState('')
    const [nom, setNom]=useState('')
    const [prenom, setPrenom]=useState('')
    const [cin, setCin]=useState('')
    const [telephone, setTelephone]=useState('')
    const [sexe, setSexe]=useState('')
    const [date_naissance, setDate_naissance]=useState('')
    const [age, setAge]=useState('')
    const [adresse, setAdresse]=useState('')
    const [email, setEmail]=useState('')
    const [technique_agricol, setTechnique_agricol]=useState('')
    const [agence, setAgenge]=useState('')
    const [erreur, setErreur]=useState({})
    const [rep_message, setRep_message]=useState('')
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors={}

    async function modifie(){
        if(!nom.trim()){
            newErrors.nom = "Le nom est requis."
        }
        if(!prenom.trim()){
            newErrors.prenom = "Le prenom est requis."
        }
        if(!technique_agricol.trim()){
            newErrors.technique_agricol = "Le technique agricol est requis."
        }
        if(!cin.trim()){
            newErrors.cin = "Le cin est requis."
        }
        if(!telephone.trim()){
            newErrors.telephone = "Le telephone est requis."
        }
        if(!date_naissance.trim()){
            newErrors.date_naissance = "Le sexe est requis."
        }
        if(!age.trim()){
            newErrors.age = "Le age est requis."
        }
        if(!adresse.trim()){
            newErrors.adresse = "Le adresse est requis."
        }
        if(!email.trim()){
            newErrors.email = "Le email est requis."
        }
        if (!emailPattern.test(email)) {
            newErrors.email = "L'email est incorrect.";
        }

        if(Object.keys(newErrors).length>0){
            setErreur(newErrors)
        }else{
            try{
                //Modifier email
                const modifie_utilisateur = await url.post("/inscription/modifie_utilisateur", {
                    email,
                    id_utilisateur:donnee.id_utilisateur
                })
                .then(response => {
                    console.log("Enregistrement utilisateur réussi:");
                })

                //Modifier fournisseur
                const modifie_fournisseur =  await url.post(`/fournisseur/modifie_fournisseur`, {
                    nom, prenom, cin, telephone, sexe, age, adresse, date_naissance,
                    technique_agricol, agence, id_fournisseur:donnee.id_fournisseur
                })
                .then(response => {
                    console.log("Resultat de modification : ");
                    setNom('')
                    setPrenom('')
                    setCin('')
                    setTelephone('')
                    setSexe('')
                    setDate_naissance('')
                    setTechnique_agricol('')
                    setAge('')
                    setAdresse('')
                    setEmail('')
                    setModal_modifie(!modal_modifie)
                    setRep_message("Modification avec succee")
                        setMessage(true);
                            setTimeout(() => {
                                setMessage(false);
                    }, 1500);
                    
                })
            }catch(error) {
                console.error("Erreur lors de l'ajout d'entreprise:", error);
            };
            
        }
        await Donnee()
    }
    const change_agence = (e)=>{
        const select= e.target.value
        setAgenge(select);
    }

  return (
    <>
        {alert_message &&<p className='message_alert'>{message}</p>}
        <div className='tete_body mt-3 row'>
            <h4 className='col-lg-6 col-md-6'>Profil</h4>
            <div className='col-lg-6 col-md-6'>
                <button className='mr' onClick={toggle_modal_modifie}>
                    <i className='fa fa-edit'/>
                    Modifie
                </button>
            </div>
        </div>

        <div className='body'>
            <div className='donne_affiche mt-3'>
                <div className='div1'>
                    <label>Nom :</label>
                    <label ref={c_nom}>{donnee.nom}</label>
                </div>
                <div className='div1'>
                    <label>Prenom :</label>
                    <label ref={c_prenom}>{donnee.prenom}</label>
                </div>
                <div className='div1'>
                    <label>Telephone :</label>
                    <label ref={c_telephone}>{donnee.telephone} </label>
                </div>
                <div className='div1'>
                    <label>Email :</label>
                    <label ref={c_email}>{donnee.Utilisateur && donnee.Utilisateur.email}</label>
                </div>
                <div className='div1'>
                    <label>Adresse :</label>
                    <label ref={c_adresse}>{donnee.adresse}</label>
                </div>
                <div className='div1'>
                    <label>CIN :</label>
                    <label ref={c_cin}>{donnee.CIN}</label>
                </div>
                <div className='div1'>
                    <label>Date de naissance :</label>
                    <label ref={c_date_naissance}>{donnee.date_naissance}</label>
                </div>
                <div className='div1'>
                    <label>Age :</label>
                    <label><span ref={c_age}>{donnee.age}</span> Ans</label>
                </div>
                <div className='div1'>
                    <label>Sexe :</label>
                    <label ref={c_sexe}>{donnee.sexe}</label>
                </div>
                <div className='div1'>
                    <label>Technique agricole :</label>
                    <label ref={c_technique_agricole}>{donnee.technique_agricole}</label>
                </div>
            </div>
        </div>

        {/* modal ajouter */}
        {modal_modifie &&(
            <div className='modal_detail'>
                <div onClick={()=>toggle_modal_modifie()} className='ferme_modal'></div>
                <div className='body_modal_details'>
                    <h4 align="center">Ajout</h4>
                    <div className=''>
                    <div className='row inscription_inputs'>
                        <div className='col-lg-6 col-md-6'>
                            <div>
                                <div className='cont_input'>
                                    <label>Nom :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Nom...'
                                    value={nom}
                                    onChange={(e)=>setNom(e.target.value)}
                                    />
                                    {erreur.nom && 
                                    <p className="error_message">{erreur.nom}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Prenom :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Prenom...'
                                    value={prenom}
                                    onChange={(e)=>setPrenom(e.target.value)}
                                    />
                                    {erreur.prenom && 
                                    <p className="error_message">{erreur.prenom}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Date de naissance :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Date...'
                                    value={date_naissance}
                                    onChange={(e)=>setDate_naissance(e.target.value)}
                                    />
                                    {erreur.prenom && 
                                    <p className="error_message">{erreur.prenom}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Age :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Age...'
                                    value={age}
                                    onChange={(e)=>setAge(e.target.value)}
                                    />
                                    {erreur.age && 
                                    <p className="error_message">{erreur.age}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>CIN :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='CIN...'
                                    value={cin}
                                    onChange={(e)=>setCin(e.target.value)}
                                    />
                                    {erreur.cin && 
                                    <p className="error_message">{erreur.cin}</p>}
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6'>
                            <div>
                                <div className='cont_input'>
                                    <label>Telephone :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Telephone...'
                                    value={telephone}
                                    onChange={(e)=>setTelephone(e.target.value)}
                                    />
                                    {erreur.telephone && 
                                    <p className="error_message">{erreur.telephone}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Sexe :</label> <br/>
                                    <select value={sexe} onChange={(e)=>setSexe(e.target.value)}>
                                        <option value="Masculin">Masculin</option>
                                        <option value="Feminin">Feminin</option>
                                    </select>
                                    {erreur.sexe && 
                                    <p className="error_message">{erreur.sexe}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Adresse :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Adresse...'
                                    value={adresse}
                                    onChange={(e)=>setAdresse(e.target.value)}
                                    />
                                    {erreur.adresse && 
                                    <p className="error_message">{erreur.adresse}</p>}
                                </div>
                                <div className='cont_input'>
                                    <label>Technique agricole :</label> <br/>
                                    <input 
                                    type='text' 
                                    placeholder='Adresse...'
                                    value={technique_agricol}
                                    onChange={(e)=>setTechnique_agricol(e.target.value)}
                                    />
                                    {erreur.technique_agricol && 
                                    <p className="error_message">{erreur.technique_agricol}</p>}
                                </div>
                                {/* <div className='cont_input'>
                                    <label>Agence :</label> <br/>
                                    <select value={agence} onChange={change_agence}>
                                        <option>-- Choisir un agence --</option>
                                        {
                                            affiche_agence.map((item)=>(
                                            <option value={item.id_agence}>{item.libelle}</option>
                                        ))}
                                    </select>
                                </div> */}
                                <div className='cont_input'>
                                    <label>Email :</label> <br/>
                                    <input 
                                    type='email' 
                                    placeholder='email...'
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    />
                                    {erreur.email && 
                                    <p className="error_message">{erreur.email}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-around gap-1'>
                        <button onClick={()=>toggle_modal_modifie()} className=''>Fermer</button>
                        <button onClick={()=>modifie()} className='text-nowrap'><i className='fa fa-edit'/> Modifier</button>
                    </div>
                </div>
                </div>
            </div>
        )}
    </>
  )
}
