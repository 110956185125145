import React, { useEffect, useState } from 'react'
import "../css/Panier.css"
import Navbar from '../../Layout/js/Navbar'
import { NavLink } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import lien from '../../../url_image';
import Footer from '../../Layout/js/Footer';

export default function Panier() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column = [
        {
            name:'Produit',
            cell: (row)=>(
                <span align="center" className='pt-1 pb-1 text-size-4'>
                    <div text="18" className='mb-1'>{row.libelle}</div>
                    <img width='100' className='' height='70' src={`${lien}/images/produits/${row.image1}`} alt={row.image1} />
                </span>
            )
        },
        {
            name:'Prix',
            selector: row=>(<>{row.prix} Ar</>)
        },
        {
            name:'Quantite',
            cell: (row)=>(
                <span className='produit_quantite mr-3 monter_zindex'>
                    <span className='btn btn' onClick={() => change_quantite(row, -1)}><i className='fa fa-minus'/></span>
                    <span className='qte btn text-nowrap'>{row.quantite} {row.Unite.abreviation}</span>
                    <span className='btn btn' onClick={() => change_quantite(row, 1)}><i className='fa fa-plus'/></span>
                </span>
            )
        },
        {
            name:'Total',
            selector: row=>(<span widith="70" className='m-0 pl-5'>{row.prix * row.quantite} Ar</span>)
        },
        {
            name:'Provenance',
            selector:row=>row.provenance
        },
        {
            name:'',
            cell: (row)=>(
            <span className='text-danger h5' onClick={() => supprimerProduit(row.id_produit)}>
                <i className='fa fa-trash del'/>
            </span>)
        }
    ]

    const [list_produits, setListe_produit] = useState([]);
    const storedListProduit = sessionStorage.getItem('list_produit');

    //affiche le produit dans le panier
    useEffect(() => {
        if (storedListProduit) {
            const parsedListProduit = JSON.parse(storedListProduit);
            setListe_produit(parsedListProduit);
        }
    }, []);

    //total de prix
    const [total_prix, setTotal_prix]=useState(0)
    const prix_totals=()=>{
        let total=0
        list_produits.map((item)=>{
            total +=item.prix * item.quantite
        })
        setTotal_prix(total)
    }

    // Supprimer
    const supprimerProduit = (produitId) => {
        const nouvelleListe = list_produits.filter(produit => produit.id_produit !== produitId);
        setListe_produit(nouvelleListe);
        sessionStorage.setItem('list_produit', JSON.stringify(nouvelleListe));
    };

    //Modifier
    const change_quantite = (item, actions) => {
        const nouvelleListe = list_produits.map(donnee => {
            if (donnee.id_produit === item.id_produit) {
                const nouvelleQuantite = donnee.quantite + actions;
                return { ...donnee, quantite: nouvelleQuantite < 1 ? 1 : nouvelleQuantite };
            }
            return donnee;
        });
        setListe_produit(nouvelleListe);
        sessionStorage.setItem('list_produit', JSON.stringify(nouvelleListe));
    };

    useEffect(()=>{
        prix_totals()
    })

  return (
    <>
    <Navbar size={list_produits.length}/>
      <div className='container mt-4 mb-5'>
        <div className='row container_panier'>
            <div className='col-lg-8 col-md-8'>
                <div className='detail_produit'>
                    <DataTable
                        columns={column}
                        data={list_produits}
                        // selectableRows
                        fixedHeader
                        // pagination
                        customStyles={style}
                    ></DataTable>
                </div>
            </div>
            <div className='col-lg-4 col-md-4' align="right">
                <div className='container_commande' align="center">
                    <div className='tete_commande'>
                        <div>Total a paye</div>
                    </div>
                    <div className='' align="center">
                        <div align="center" className='p-2'>{total_prix} Ar</div>
                    </div>
                    <NavLink className="lien_span" to="/payement">
                        <div className='btn_commande'>Commander</div>
                    </NavLink>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}
