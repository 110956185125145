import React, { useEffect, useState } from 'react'
import {FaStar} from "react-icons/fa"
import url from '../../../url'
import Cookies from 'universal-cookie';

export default function Rating(props) {
    //securisation
    const [message, setMessage] = useState('');
    const [modal_message, setModal_message] = useState(false);
    const [rating, setRating] = useState('');
    const [hover, setHover] = useState('');
    const id_produit = props.id_produit;

    const cookies = new Cookies();
    const [id, setId] = useState('');
    const [role, setRole]=useState('')

    useEffect(() => {
        const id_cookie = cookies.get('id_front');
        const role_cookie = cookies.get('role_front');
        setId(id_cookie || '');
        setRole(role_cookie || '')
        
    }, [role]);
    //securisation

    //get somme rating
    const [somme_rating, setSomme_rating] = useState(0);
    const [count_rating, setCount_rating] = useState(0);

async function Somme_rating() {
    try {
        const response = await url.get(`/produit/getSomme_produit/${id_produit}`);
        setSomme_rating(response.data.somme);
    } catch (e) {
        console.log('Erreur : ', e)
    }
}

async function Count_rating() {
    try {
        const response = await url.get(`/produit/getCount_vote`);
        setCount_rating(response.data.count);
    } catch (e) {
        console.log('Erreur : ', e)
    }
}

useEffect(() => {
    if (id_produit) {
        (async () => await Somme_rating())()
    }
}, []);

useEffect(() => {
    if (id_produit) {
        (async () => await Count_rating())()
    }
}, []);

const moyenne_rating = count_rating !== 0 ? somme_rating / count_rating : 0

    const handleRatingChange = (value) => {
        setRating(value);

        if(role==="client"){
            url.post("/produit/star", {
                id_produit,
                nombre_star:value,
                id_utilisateur: id
            })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            Somme_rating()
            Count_rating()
            Somme_rating()
            Somme_rating()
            
            //Modifier produit
            url.post("/produit/modifie_popularite_produit", {
                somme_rating,
                count_rating,
                id_produit,
                moyenne_rating
            })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        }else{
            setMessage('Vous devez vous connecter en tant que client');
            setModal_message(true);
            setTimeout(() => {
                setModal_message(false);
            }, 2000);
        }
    };

    return (
        <>
        {modal_message && <h5 className='erreur'>{message}</h5>}
            {[...Array(5)].map((star, index) => {
                const current_rating = index + 1;
                return (
                    <label key={current_rating}>
                        <input
                            type='radio'
                            className='radio_rating'
                            name='rating'
                            value={current_rating}
                            onClick={() => handleRatingChange(current_rating)}
                        />
                        <FaStar
                            className='star'
                            size={35}
                            color={current_rating <= (hover || rating) ? "#FF3839" : "#dac1c1"}
                            onMouseEnter={() => setHover(current_rating)}
                            onMouseLeave={() => setHover(null)}
                        />
                    </label>
                );
            })}
        </>
    );
}

