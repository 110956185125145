import React, { useEffect, useState } from 'react'
import url from "../../url"
import DataTable from 'react-data-table-component'
import lien from '../../url_image';
import date_pubs from '../../fonction_date';

export default function Categorie() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column=[
        {
            name:'Catégorie',
            selector: row=>row.libelle_categorie,
            sortable:true
        },
        {
            name:'Image',
            selector: row=>(<div className='image_data'>
            <img className='' src={`${lien}/images/produits/${row.image}`} alt={row.image} />
            </div>),
            sortable:true
        },
        {
            name:'Date de publication',
            selector: row=>(<>{date_pubs(row.createdAt)}</>),
            sortable:true
        },
        {
            name: 'Action',
            cell: (row) => (
              <>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-info mr-2"
                    onClick={() => toggle_modal_modifie(row.id_categorie, row.libelle_categorie, row.image)}
                    ><i className="fa fa-edit" /></button>
                </span>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => fonction_supprime(row.id_categorie)}
                    ><i className="fa fa-trash" /></button>
                </span>
              </>
            ),
        }
    ]

    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            const response = await url.get("/categorie/affiche");
            setDonnee(response.data);
            setFiltre_donnee(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Donnee())()
    },[])
    const handlefilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const new_data = filtre_donnee.filter(row => 
            row.libelle_categorie.toLowerCase().includes(searchValue)
        );
        setDonnee(new_data);
    };

    //Detail de donnee
    const [message, setMessage]=useState('')
    const [alert_message, setAlert_message]=useState(false)

    // supprimer le donner
    const [modal_suprime, setModal_suprime]=useState(false)
    function fonction_supprime(id_categorie){
        setModal_suprime(!modal_suprime)
        setId_categorie(id_categorie)
    }
    async function supprime_utilisateur(){
        await url.delete(`/categorie/supprime_categorie/${id_categorie}`)
        await Donnee()
        setMessage("Suppression avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
        setModal_suprime(!modal_suprime)
    }

    // ajout publicite
    const [categorie, setCategorie]=useState('')
    const [id_categorie, setId_categorie]=useState('')
    const [image, setImage]=useState(null)
    const [modal_ajout, setModal_ajout]=useState(false)
    const newErrors={}
    const [erreur, setErreur]=useState({})
    function toggle_modal_ajout(){
        setModal_ajout(!modal_ajout)
    }
    async function ajout_donnee(){
        const formdata = new FormData()
        formdata.append('image', image)
        formdata.append('categorie', categorie)
        // if(!image.trim()){
        //     newErrors.image = "Le image est requis"
        // }
        if(!categorie.trim()){
            newErrors.categorie = "Le nom du categorie est requis"
        }

        if(Object.keys(newErrors).length>0){
            setErreur(newErrors)
        }else{
            await url.post("/categorie/ajout_categorie", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            await Donnee()
            .then(response => {
                setMessage("Ajout avec succee")
                setAlert_message(true)
                setTimeout(()=>{
                    setAlert_message(false)
                }, 1500)
            })
            .catch(error => {
                console.error(error);
            });
            setCategorie('')
            setImage(null)
            setModal_ajout(!modal_ajout)
        }
    }

    // Modifie du publicite
    const [modal_modifie, setModal_modifie]=useState(false)
    function toggle_modal_modifie(id_categorie,categorie, image){
        setId_categorie(id_categorie)
        setCategorie(categorie)
        setImage(image)
        setModal_modifie(!modal_modifie)
    }
    async function modifie_donnee(){
        // alert(image)
        const formdata = new FormData()
        formdata.append('image', image)
        formdata.append('categorie', categorie)
        formdata.append('id_categorie', id_categorie)
        // if(!image.trim()){
        //     newErrors.image = "Le image est requis"
        // }
        if(!categorie.trim()){
            newErrors.categorie = "Le nom du categorie est requis"
        }

        if(Object.keys(newErrors).length>0){
            setErreur(newErrors)
        }else{
            await url.post("/categorie/modifie_categorie", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            await Donnee()
            .then(response => {
                setMessage("Modification avec succee")
                setAlert_message(true)
                setTimeout(()=>{
                    setAlert_message(false)
                }, 1500)
            })
            .catch(error => {
                console.error(error);
            });
            setModal_modifie(!modal_modifie)
        }
    }

  return (
    <>
        {alert_message &&<p className='message_alert'>{message}</p>}
        <div className='tete_body mt-3 row'>
            <h4 className='col-lg-6 col-md-6'>Géstion de catégorie</h4>
            <div className='col-lg-6 col-md-6'>
                <input type='text' 
                placeholder='Recherche...'
                onChange={handlefilter}
                />
                <button className='mr' onClick={toggle_modal_ajout}>
                    <i className='fa fa-plus'/>
                    Ajouter
                </button>
            </div>
        </div>

        <div className='body'>
            <DataTable
                columns={column}
                data={donnee}
                // selectableRows
                fixedHeader
                pagination
                customStyles={style}
            ></DataTable>
        </div>

        {/* modal ajouter */}
        {modal_ajout &&(
            <div className='modal_detail'>
                <div onClick={()=>toggle_modal_ajout()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Ajout</h4>
                    <div className='container_input'>
                        <div className='cont_input'>
                            <label>Nom du categorie :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Titre...'
                            value={categorie}
                            onChange={(e)=>setCategorie(e.target.value)}
                            />
                            {erreur.titre && 
                            <p className="error_message">{erreur.titre}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image :</label> <br/>
                            <input 
                            type='file' 
                            placeholder='Image...'
                            onChange={(e)=>setImage(e.target.files[0])}
                            />
                            {erreur.image && 
                            <p className="error_message">{erreur.image}</p>}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <button onClick={()=>toggle_modal_ajout()}>Fermer</button>
                            <button onClick={()=>ajout_donnee()}>Enregitrer</button>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {/* Modal Modiffier */}
        {modal_modifie &&(
            <div className='modal_detail'>
                <div onClick={()=>toggle_modal_modifie()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Modification</h4>
                    <div className='container_input'>
                        <div className='cont_input'>
                            <label>Nom du categorie :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Titre...'
                            value={categorie}
                            onChange={(e)=>setCategorie(e.target.value)}
                            />
                            {erreur.titre && 
                            <p className="error_message">{erreur.titre}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Image :</label> <br/>
                            <input 
                                type='file' 
                                placeholder='Image...'
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                            {erreur.image && 
                            <p className="error_message">{erreur.image}</p>}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <button onClick={()=>toggle_modal_modifie()}>Fermer</button>
                            <button onClick={()=>modifie_donnee()}>Modifier</button>
                        </div>
                    </div>
                </div>
            </div>
        )}

        {/* modal supprimer */}
        {modal_suprime &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_supprime()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Suppression</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la suppression</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>fonction_supprime()}>Fermer</button>
                        <button onClick={()=>supprime_utilisateur()}>Supprimer</button>
                    </span>
                </div>
            </div>
        )}
    </>
  )
}
