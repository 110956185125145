import React, { useEffect, useState } from 'react'
import Navbar from '../../Layout/js/Navbar'
import Publicite from './Publicite'
import Categorie from './Categorie'
import Footer from '../../Layout/js/Footer'
import "../css/Acceuil.css"
import Produit_populaire from './Produit_populaire'
import { NavLink } from 'react-router-dom'

export default function Acceuil() {
  //affiche le produit dans le panier
  const storedListProduit = sessionStorage.getItem('list_produit');
  const [list_produits, setListe_produit] = useState([]);
  useEffect(() => {
      if (storedListProduit) {
          const parsedListProduit = JSON.parse(storedListProduit);
          setListe_produit(parsedListProduit);
      }
  }, []);
  return (
    <>
      <Navbar size={list_produits.length}/>
      <div className='container_acceuil p-3' align="center">
        <Publicite align="center"/>
          <div class="sous_contenu_tete_acceuil">
              <div class="bienvenu centre">
                  <p>
                  {/* Bienvenu a */}
                  </p><br/> <br/>
              </div>
              <div className='venture_mere'>
                <div class="venture">
                    <div class="centre prem"><p><span>E - </span>TANTSAHA</p></div>
                    <div class="centre dex"><p>J'AI DE LA CHANCE</p></div>
                </div>
              </div>
              <p class="petite_text centre">Une plateforme de commerce électronique qui vise à 
              connecter les agriculteurs directement avec les consommateurs.
              </p>
              <div class="btn_contenu_acceuil centre">
                  <NavLink to="/shop"><button class="commence_btn_contenu_acceuil">Commencer</button></NavLink>
                  {/* <div class="recherche_contenu_acceuil">
                      <i className='fa fa-search icone_cherche'/>
                      <input type="text" placeholder='Recherche...'/>
                  </div> */}
              </div>
          </div>
      </div>
      <Categorie/>
      <Produit_populaire/>
      <Footer/>
    </>
  )
}
