import React, { useEffect, useState } from 'react'
import "../css/Publicite.css"
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import data_pub from './faux/data_pub';
import url from '../../../url';
import lien from '../../../url_image';

export default function Publicite() {
  const [publicite, setPublicite]=useState([])
   useEffect(()=>{
       url.get("/publicite/affiche").then((response)=>{
        setPublicite(response.data)
       }).catch((error)=>{
           console.log(error)
       })
   }, [])
  return (
    <>
        <div align="center" className='container p-1 container_pub row'>
        <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              EffectCube,
              Autoplay,
            ]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={true}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
      {publicite.map((item)=>(
        <SwiperSlide align="center" key={publicite.id_publicite} className="">
          <div className=' sous_container_pub row'>
            <div className='container_pub1 col-lg-6 col-md-12'>
                <div className='text_pub'>
                    <span>Pub</span>
                </div>
                <div className='info_pub'>
                    <p align="left" className='titre'>{item.titre}</p>
                    <div><p align="left">{item.lieu}</p></div>
                </div>
            </div>
            <div className='container_pub2 col-lg-6 col-md-12'>
                <div className='desc_pub'>
                    <p>{item.description}</p>
                </div>
                <div className='img_pub' >
                    <img src={`${lien}/images/publicites/${item.image}`} alt={item.image} />
                </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
      </Swiper>
        </div>
    </>
  )
}
