import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import "../css/Navbar.css"
import Cookies from 'universal-cookie'
import url from '../../../url'

 const Navbar= ({size})=> {
    const [affiche_panier, setAffiche_panier]=useState(false)
        useEffect(()=>{
        if(size===0){
            setAffiche_panier(false)
        }else{
            setAffiche_panier(true)
        }
    }, [size])

    const navigate = useNavigate()
    const [ovrir_nav, setOuvrir_nav]=useState('')
    const toggleNav = () => {
        setOuvrir_nav(!ovrir_nav);
      };

    const [navbar, setNavbar]=useState('')
    const change_coleur=()=>{
        if(window.scrollY>=30){
            setNavbar(true)
        }else{
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', change_coleur)

    const deconnecte = ()=>{
        cookies.remove('id_front')
        cookies.remove('role_front')
        window.location.href = '/login'
    }

    const cookies=new Cookies()
    const [visible_profil, setVisible_profil]=useState(false)
    const [visible_connect, setVisible_connect]=useState(false)
    const [visible_deconnect, setVisible_deconnect]=useState(false)
    const [visible_admin, setVisible_admin]=useState(false)
    const [visible_fournisseur, setVisible_fournisseur]=useState(false)
    const [id_cookie, setId_cookie]=useState('')
    const [role_cookie, setRole_cookie]=useState('')

    useEffect(() => {
        const id_front = cookies.get('id_front');
        const role_front = cookies.get('role_front');
        setId_cookie(id_front);
        setRole_cookie(role_front);
    }, []);

    useEffect(() => {
        if (role_cookie==="client") {
            setVisible_profil(true)
            setVisible_admin(false);
            setVisible_fournisseur(false);
            setVisible_connect(false)
            setVisible_deconnect(true)
        } else if (role_cookie==="admin"){
            setVisible_profil(false)
            setVisible_admin(true);
            setVisible_fournisseur(false);
            setVisible_connect(false)
            setVisible_deconnect(true)
        }
        else if (role_cookie==="fournisseur"){
            setVisible_profil(false)
            setVisible_admin(false);
            setVisible_fournisseur(true);
            setVisible_connect(false)
            setVisible_deconnect(true)
        }else{
            setVisible_profil(false)
            setVisible_admin(false);
            setVisible_fournisseur(false);
            setVisible_connect(true)
            setVisible_deconnect(false)
        }
    }, [role_cookie]);

  return (
    <>
    <div className={`container_navbar ${ovrir_nav ? 'active' : ''}`} id='container_navbar'>
      <div className='container'>
        <div className='navbar'>
        <NavLink to='/'>
            <img className='logo_navbar' src='images/logo_fond_vert.jpeg' alt='logo'/>
            </NavLink>
            <div className='lieu_profil'>
                <div className={`container_lien_nav ${ovrir_nav ? 'active' : ''}`}>
                    <div className={navbar ? 'lien_nav active' : 'lien_nav'} id='lien_nav'>
                        <NavLink to='/' className="lien_span" ><span>Acceuil</span></NavLink>
                        <NavLink to='/shop' className="lien_span" ><span>Shop</span></NavLink>
                        <NavLink to='/apropos' className="lien_span" ><span>Apropos</span></NavLink>
                        <NavLink to='/contact' className="lien_span"><span>Contact</span></NavLink>
                    </div>
                    {affiche_panier &&
                    <NavLink className="lien_span" to="/panier">
                        <span className='icone_panier'>
                            <i className='fas fa-shopping-cart'/>
                            <span>{size}</span>
                        </span> 
                    </NavLink>}
                    {visible_profil &&(
                    <NavLink className="lien_span" to="/profil">
                        <span className='icone_profil'>
                            <i className='fa fa-user'/>
                            <span>Profil</span>
                        </span>
                    </NavLink>
                    )}
                    {visible_admin &&(
                        <NavLink className="lien_span" to="/gestion_statistique">
                            <span className='icone_profil'>
                                <i className='fa fa-user'/>
                                <span>Admin</span>
                            </span>
                        </NavLink>
                    )}
                    {visible_fournisseur &&(
                        <NavLink className="lien_span" to="/profil_fournisseur">
                            <span className='icone_profil'>
                                <i className='fa fa-user'/>
                                <span>Profil</span>
                            </span>
                        </NavLink>
                    )}
                    {/* <NavLink className="lien_span" to="/login"> */}
                    {visible_deconnect &&(
                        <span onClick={deconnecte} className='icone_profil'>
                            <i className='fa fa-sign-out'/>
                            <span>Se déconnecter</span>
                        </span>
                    )}
                    {/* </NavLink> */}
                    {visible_connect &&(
                    <NavLink className="lien_span" to="/login">
                        <span className='icone_profil'>
                            <i className='fa fa-sign-in'/>
                            <span>Se connecter</span>
                        </span>
                    </NavLink>)}
                </div>
                <span onClick={toggleNav} 
                className={`icone_ferme_navbar ${ovrir_nav ? 'active' : ''}`}>
                    <i className='fa fa-times icone'/>
                </span>
                <span onClick={toggleNav} 
                className={`icone_ouvert_navbar ${ovrir_nav ? 'active' : ''}`}>
                    <i className='fa fa-bars icone'/>
                </span>
            </div>
        </div>
      </div>
    </div>
    <div className='sous_navbar'>
        {/* <NavLink className="lien_span_sous1" to="/"><span>Acceuil</span></NavLink>
        <NavLink className="lien_span_sous1" to="/shop"><span>Shop</span></NavLink>
        <NavLink className="lien_span_sous1" to="/apropos"><span>Apropos</span></NavLink>
        <NavLink className="lien_span_sous1" to="/contact"><span>Contact</span></NavLink> */}
    </div>
    </>
  )
}

export default Navbar