import React, { useEffect, useRef, useState } from 'react'
import "../css/Profil.css"
import Historique from './Historique'
import url from '../../../url'
import Navbar from '../../Layout/js/Navbar'
import Footer from '../../Layout/js/Footer'
import Cookies from 'universal-cookie'

export default function Profil() {
    //securisation
    const cookies=new Cookies()
    const [id_cookie, setId_cookie] = useState('');
    const [role_cookie, setRole_cookie] = useState();
    useEffect(() => {
        const idFromCookie = cookies.get('id_front');
        const roleFromCookie = cookies.get('role_front');
        setId_cookie(idFromCookie);
        setRole_cookie(roleFromCookie);
      }, [cookies]);
    //securisation

    //Affiche client
    const [client, setClient]=useState([])
    async function Load(){
        try{
            url.get(`/inscription/affiche_client_connecte/${id_cookie}`)
            .then((response) => {
                setClient(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        }catch(erreur){
            console.log(erreur)
        }
    }
    useEffect(()=>{
        if(id_cookie){
            (async()=> await Load())()
        }
    }, [id_cookie])
    // Modification
    const [nom, setNom]=useState('')
    const [prenom, setPrenom]=useState('')
    const [cin, setCin]=useState('')
    const [telephone, setTelephone]=useState('')
    const [sexe, setSexe]=useState('')
    const [age, setAge]=useState('')
    const [adresse, setAdresse]=useState('')
    const [email, setEmail]=useState('')
    const [date_naissance, setDate_naissance]=useState('')
    const [erreur, setErreur]=useState({})
    const [modal_modifie, setModal_modifie]=useState(false)
    const [information_profil, setInformation_profil]=useState(true)
    const [historique, setHistorique]=useState(false)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors={}
    const [message, setMessage]=useState('')
    const [model_message, setModal_message]=useState(false)

    //Element HTML
    const c_nom=useRef(null)
    const c_prenom=useRef(null)
    const c_telephone=useRef(null)
    const c_cin=useRef(null)
    const c_adresse=useRef(null)
    const c_sexe=useRef(null)
    const c_age=useRef(null)
    const c_email=useRef(null)
    const c_date_naissance=useRef(null)

    const modifie = ()=>{
        if(!nom.trim()){
            newErrors.nom = "Le nom est requis."
        }
        if(!prenom.trim()){
            newErrors.prenom = "Le prenom est requis."
        }
        if(!cin.trim()){
            newErrors.cin = "Le cin est requis."
        }
        if(!telephone.trim()){
            newErrors.telephone = "Le telephone est requis."
        }
        if(!sexe.trim()){
            newErrors.sexe = "Le sexe est requis."
        }
        if(!age.trim()){
            newErrors.age = "Le age est requis."
        }
        if(!adresse.trim()){
            newErrors.adresse = "Le adresse est requis."
        }
        if(!email.trim()){
            newErrors.email = "Le email est requis."
        }
        if (!emailPattern.test(email)) {
            newErrors.email = "L'email est incorrect.";
        }
        if(Object.keys(newErrors).length>0){
            setErreur(newErrors)
        }else{
            url.post("inscription/modifie_utilisateur",{
                email
            })
            .catch(error => {
                console.error("Erreur lors de l'ajout d'entreprise:", error);
            });
            url.post("inscription/modifie_client",{
                nom, prenom, cin, telephone, sexe, age, adresse, date_naissance
            }).then(response => {
                Load()
                setModal_modifie(false)
                setMessage(response.data)
                setModal_message(true)
                setTimeout(()=>{
                    setModal_message(false)
                }, 1500)
            })
            .catch(error => {
                console.error("Erreur lors de l'ajout d'entreprise:", error);
            });
        }
    }

    const toggle_modal_modifie = ()=>{
        setNom(c_nom.current.textContent)
        setPrenom(c_prenom.current.textContent)
        setTelephone(c_telephone.current.textContent)
        setCin(c_cin.current.textContent)
        setAdresse(c_adresse.current.textContent)
        setSexe(c_sexe.current.textContent)
        setAge(c_age.current.textContent)
        setEmail(c_email.current.textContent)
        setDate_naissance(c_date_naissance.current.textContent)
        setModal_modifie(!modal_modifie)
    }

    const affiche_information_profil = ()=>{
        setInformation_profil(true)
        setHistorique(false)
    }

    const affiche_historique = ()=>{
        setHistorique(true)
        setInformation_profil(false)
    }

    //Modification
  return (
    <>
    {model_message && <div className='erreur'>{message}</div>}
    <Navbar/>
      <div className='container'>
        <div className='container_profil mt-4'>
            <div className='tete_container_profil'>
                <div className='row tete_profil'>
                    <div className='col-lg-3 col-md-6 mt-3 titre_profil'>
                        <h3>Profil du client</h3>
                    </div>
                    <div className='col-lg-9 col-md-6 nav_profil_mini' align="center">
                        <div className='nav_profil' align="center">
                            <div className='row container_nav_profil' align="center">
                                <div onClick={affiche_information_profil} 
                                className={`col-lg-6 btn_profil ${information_profil ? 'active' : ''}`}>
                                    <label onClick={affiche_information_profil}>Profil</label>
                                </div>
                                <div onClick={affiche_historique} 
                                className={`col-lg-6 btn_historique ${historique ? 'active' : ''}`}>
                                    <label onClick={affiche_historique}>Historique</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-3 col-md-6'>
                    <div className='information_photo_profil pt-2' align="center">
                        <div className='photo_profil' style={{backgroundImage:`url(images/icone/user.png)`,
                            backgroundPosition:'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            // padding:'10px'
                        }}>
                            {/* <span>
                                <i className='fa fa-camera'/>
                                <input type='file'/>
                            </span> */}
                        </div>
                        <h5 className='mt-2'>{client.nom} {client.prenom}</h5>
                        <p className='m-1'>Client fidele</p>
                        <p>{client.adresse}</p>
                        <div className='lien_img_profil pb-2'>
                            {/* <img src='images/icone_fb.png'/>
                            <img src='images/icone_insta.png'/> */}
                        </div>
                    </div>
                </div>
                <div className='col-lg-9 col-md-6'>
                    <div className='nav_profil_minu' align="center">
                        <div className='container_nav_profil' align="center">
                            <div onClick={affiche_information_profil} 
                            className={`btn_profil ${information_profil ? 'active' : ''}`}>
                                Profil
                            </div>
                            <div onClick={affiche_historique} 
                            className={`btn_historique ${historique ? 'active' : ''}`}>
                                Historique
                            </div>
                        </div>
                    </div>
                    {information_profil &&
                        <span>
                            <div className='information_profil'>
                                <div className='tete_info_profil'>
                                    <label className='titre_info'>Information sur le client</label>
                                    <label>
                                        <button onClick={toggle_modal_modifie}>
                                            <i className='fa fa-edit'/>
                                            Modifier
                                        </button>
                                    </label>
                                </div>
                                    <div>
                                        <label>Nom :</label>
                                        <label ref={c_nom}>{client.nom}</label>
                                    </div>
                                    <div>
                                        <label>Prenom :</label>
                                        <label ref={c_prenom}>{client.prenom}</label>
                                    </div>
                                    <div>
                                        <label>Telephone :</label>
                                        <label ref={c_telephone}>{client.telephone}</label>
                                    </div>
                                    <div>
                                        <label>CIN :</label>
                                        <label ref={c_cin}>{client.CIN}</label>
                                    </div>
                                    <div>
                                        <label>Adresse :</label>
                                        <label ref={c_adresse}>{client.adresse}</label>
                                    </div>
                                    <div>
                                        <label>Sexe :</label>
                                        <label ref={c_sexe}>{client.sexe}</label>
                                    </div>
                                    <div>
                                        <label>Age :</label>
                                        <label ref={c_age}>{client.age} ans</label>
                                    </div>
                                    <div>
                                        <label>Date de naissance :</label>
                                        <label ref={c_date_naissance}>{client.date_naissance}</label>
                                    </div>
                                    <div>
                                        <label>E-mail :</label>
                                        <label ref={c_email}>{client && client.Utilisateur && 
                                        client.Utilisateur.email}</label>
                                    </div>
                            </div>
                        </span>
                    }
                    {historique && <Historique/>}
                </div>
            </div>
        </div>
      </div>

 {/* fenetre modal */}
 {modal_modifie &&
    <div className='container_modal'>
        <div onClick={toggle_modal_modifie} className='ferme_modal'></div>
        <div className='body_modal body_modal_details alert custom-alert'>
        <h4 align="center">Modification</h4>
            <div className='row input_modifie'>
                <div className='col-lg-6 col-md-6'>
                    <div>
                        <div className='cont_input'>
                            <label>Nom :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Nom...'
                            value={nom}
                            onChange={(e)=>setNom(e.target.value)}
                            />
                            {erreur.nom && <p className="error_message">{erreur.nom}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Prenom :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Prenom...'
                            value={prenom}
                            onChange={(e)=>setPrenom(e.target.value)}
                            />
                            {erreur.prenom && <p className="error_message">{erreur.prenom}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>CIN :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='CIN...'
                            value={cin}
                            onChange={(e)=>setCin(e.target.value)}
                            />
                            {erreur.cin && <p className="error_message">{erreur.cin}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Telephone :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Telephone...'
                            value={telephone}
                            onChange={(e)=>setTelephone(e.target.value)}
                            />
                            {erreur.telephone && <p className="error_message">{erreur.telephone}</p>}
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 col-md-6'>
                    <div>
                        <div className='cont_input'>
                            <label>Date de naissance :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Date de naissance...'
                            value={date_naissance}
                            onChange={(e)=>setDate_naissance(e.target.value)}
                            />
                            {erreur.age && <p className="error_message">{erreur.age}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Age :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Age...'
                            value={age}
                            onChange={(e)=>setAge(e.target.value)}
                            />
                            {erreur.age && <p className="error_message">{erreur.age}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Adresse :</label> <br/>
                            <input 
                            type='text' 
                            placeholder='Adresse...'
                            value={adresse}
                            onChange={(e)=>setAdresse(e.target.value)}
                            />
                            {erreur.adresse && <p className="error_message">{erreur.adresse}</p>}
                        </div>
                        <div className='cont_input'>
                            <label>Email :</label> <br/>
                            <input 
                            type='email' 
                            placeholder='email...'
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                            />
                            {erreur.email && <p className="error_message">{erreur.email}</p>}
                        </div>
                    </div>
                </div>
                <div className='cont_input'>
                    <label>Sexe :</label> <br/>
                    <select value={sexe} onChange={(e)=>setSexe(e.target.value)}>
                        <option value="Masculin">Masculin</option>
                        <option value="Feminin">Feminin</option>
                    </select>
                    {erreur.sexe && <p className="error_message">{erreur.sexe}</p>}
                </div>
                <div className='btn_modifie'>
                    <button className='btn_ferme_modif mt-3 mb-3' onClick={toggle_modal_modifie}>Fermer</button>
                    <button className='btn_modif mt-3 mb-3' onClick={modifie}><i className='fa fa-edit'/> Modifier</button>
                </div>
            </div>
        </div>
    </div>
 }
    <Footer/>
    </>
  )
}
