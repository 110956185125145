import React, { useEffect, useState } from 'react'
import "../css/Sidebar.css"
import { NavLink } from 'react-router-dom'
import url from '../../../url'
import Cookies from 'universal-cookie'

export default function Sidebar({ show_sidebar_function, show_sidebars }) {
    const [lien_utilisateur, setLiens_utilisateur] = useState(false)
    const [icone, setIcone] = useState(false)

    //securisation
    const cookies=new Cookies()
    const [id_cookie, setId_cookie] = useState('');
    const [role_cookie, setRole_cookie] = useState();
    useEffect(() => {
        const idFromCookie = cookies.get('id_front');
        const roleFromCookie = cookies.get('role_front');
        setId_cookie(idFromCookie);
        setRole_cookie(roleFromCookie);
      }, [cookies]);
    //securisation

    function show() {
        setLiens_utilisateur(!lien_utilisateur)
        setIcone(!icone)
    }

    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            const response = await url.get(`/fournisseur/affiche_fournisseur_connecte/${id_cookie}`);
            setDonnee(response.data);
            setFiltre_donnee(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        if(id_cookie){
            (async()=> await Donnee())()
        }
    },[id_cookie])

    return (
        <>
            <div className={`div_fereme_sidebar ${show_sidebars ? "" : "active"}`} 
            onClick={() => show_sidebar_function()}></div>
            <div className={`p-2 container_sidebar ${show_sidebars ? "" : "active"}`}>
                <div className='image_logo_admin'>
                    <img className={`img ${show_sidebars ? "" : "active"}`} 
                    src='images/logo_fond_blanc.jpeg' alt="Logo" />
                    <span onClick={() => show_sidebar_function()}>
                        <i className='fa fa-bars' />
                    </span>
                </div>
                <div className='container_lien_admin'>
                    <NavLink className="text-black text-decoration-none" to='/profil_fournisseur'>
                        <div className='titre_admin'>
                            <span>
                                <i className='fa fa-user' />
                            </span>
                            <label className={`disparu ${show_sidebars ? "" : "active"}`}>{donnee.nom}</label>
                        </div>
                    </NavLink>
                    <div className='liens1'>
                        <NavLink className="text-black text-decoration-none" to='/gestion_produit_fournisseur'>
                            <div className={`btn_ouvert ${!lien_utilisateur ? "" : "active"}`} onClick={() => show()}>
                                <span>
                                    <img src='images/icone/produit.png' className='icones_side' />
                                    <label className={`disparu ${show_sidebars ? "" : "active"}`}>Produit</label>
                                </span>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}
