import React, { useEffect, useState } from 'react'
import Navbar from '../../Layout/js/Navbar';
import Footer from '../../Layout/js/Footer';
import "../css/Contact.css"
import url from '../../../url';

export default function Contact() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [message_notif, setMessage_notif] = useState("");
    const [errors, setErrors] = useState({});
    const [alert_message, setAlert_message]=useState(false)
    
    async function handleSubmit(){
        const newErrors = {};

        if (!fullName.trim()) {
          newErrors.fullName = "Le nom complet est requis.";
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
          newErrors.email = "L'e-mail est incorrect.";
        }

        if (!email.trim()) {
          newErrors.email = "L'e-mail est requis.";
        }

        if (!message.trim()) {
          newErrors.message = "Le message est requis.";
        }

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
            await url.post('/send_email/send_email',{
                nom: fullName, email, message
            })
            setMessage_notif("Votre e-mail est bien ete envoye")
            setAlert_message(true)
            setTimeout(()=>{
                setAlert_message(false)
            }, 1500)

            // setFullName('')
            // setEmail('')
            // setMessage('')
            alert()
        }
      };

      //Panier
  const [list_produit, setListe_produit]=useState([])
  const storedListProduit = sessionStorage.getItem('list_produit');
  useEffect(() => {
      if (storedListProduit) {
          const parsedListProduit = JSON.parse(storedListProduit);
          setListe_produit(parsedListProduit);
      }
  }, []);
  return (
    <>
    {alert_message &&<p className='message_alert'>{message_notif}</p>}
    <Navbar size={list_produit.length}/>
        <div className='container_contact'>
            <div className='container sous_ecran'>
                <div className='row mr-2 ml-2'>
                    <div className='container1 col-lg-6 col-md-6 mb-3'>
                        <div className='mr'>
                            <div className='sous_titre_contact'>
                                <span className='ligne'></span>
                                <span className='text'>Contact</span>
                            </div>
                            <div className='grand_titre_contact'>
                                <div className='span1'>
                                    <span className='span_rouge'>Contactez</span>
                                    <span className='span_noir'>-nous</span>
                                </div>
                                <div className='span2'>Travaillons ensemble</div>
                            </div>
                            <div className='text_contact'>
                                <p>
                                    Notre objectif est de supprimer les intermédiaires traditionnels, 
                                    tels que les grossistes et les détaillants, afin de garantir une 
                                    meilleure qualité des produits et une juste rémunération pour les 
                                    agriculteurs.ns.
                                </p>
                            </div>
                            <div className='input_contact'>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Votre nom complet"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        className={errors.fullName ? "error" : ""}
                                    />
                                    {errors.fullName && <p className="error_message">{errors.fullName}</p>}

                                    <input
                                        type="text"
                                        placeholder="Votre e-mail"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className={errors.email ? "error" : ""}
                                    />
                                    {errors.email && <p className="error_message">{errors.email}</p>}

                                    <textarea
                                        placeholder="Votre message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className={errors.message ? "error" : ""}
                                    />
                                    {errors.message && <p className="error_message">{errors.message}</p>}
                                </div>
                                <div>
                                    <button className="contact_button mb-4" onClick={handleSubmit}>
                                        Envoyer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container2_contact col-lg-6 col-md-6'>
                        <img src='images/img_contact.png' alt='image contact'/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>
  )
}