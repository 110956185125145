import React, { useEffect, useState } from 'react'
import url from "../../url"
import DataTable from 'react-data-table-component'
import date_pubs from '../../fonction_date';
import lien from '../../url_image';
import './Commande.css'

export default function Commande() {
    const style = {
        headCells: {
          style: {
            fontSize: '16px',
            textAlign: 'center'
          },
        },
    };
    const column=[
        {
            name:'Nom du client',
            selector: row=>row.Client.nom,
            sortable:true
        },
        {
            name:'Téléphone du client',
            selector: row=>row.Client.telephone,
            sortable:true
        },
        {
            name:'Lieu de livraison',
            selector: row=>row.lieu_livraison,
            sortable:true
        },
        {
            name: 'Type de paiement',
            cell: (row) => (
            <>
                {!row.Payement.reference ? (
                <span className='m-1'>Par livraison</span>
                ) : (
                <span className='m-1 text-nowrap'>{row.Payement.reference}</span>
                )}
            </>
            )
        },
        {
            name:'Etat',
            cell: (row)=>(
                <>
                    {row.Payement.status === 1 ? (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => payement_fonct(row.id_payement, row.id_commande, row.Client.nom, 
                            row.lieu_livraison, !row.Payement.reference ? 'Par livraison' : 'Enligne', 
                            date_pubs(row.date_com))}
                        >Payer</button>
                    </span>
                ) : (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => payement_fonct(row.id_payement, row.id_commande, row.Client.nom, 
                            row.lieu_livraison, !row.Payement.reference ? 'Par livraison' : 'Enligne', 
                            date_pubs(row.date_com))}
                        >Non payer</button>
                    </span>
                )}
                </>
            )
        },
        {
            name:'Date du commande',
            selector: row=>(<>{date_pubs(row.date_com)}</>),
            sortable:true
        },
        {
            name: 'Action',
            cell: (row) => (
              <>
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-info mr-2"
                    onClick={() => fonction_detail(row.id_commande)}
                    ><i className="fa fa-eye" /></button>
                </span>
                {row.validation === 1 ? (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => valide_donnee(row.id_commande)}
                        ><i className="fa fa-check" /></button>
                    </span>
                ) : (
                    <span className='m-1'>
                        <button
                        type="button"
                        className="btn btn-info mr-2"
                        onClick={() => valide_donnee(row.id_commande)}
                        ><i className="fas fa-times" /></button>
                    </span>
                )}
                <span className='m-1'>
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => fonction_supprime(row.id_commande)}
                    ><i className="fa fa-trash" /></button>
                </span>
              </>
            ),
        }
    ]

    const column_detail=[
        {
            name:'Produit',
            cell: (row)=>(
                <span align="center" className='pt-1 pb-1 text-size-4'>
                    <div text="18" className='mb-1'>{row.Produit.libelle}</div>
                    <img width='100' height='70' src={`${lien}/images/produits/${row.Produit.image1}`} alt={row.Produit.image1} />
                </span>
            )
        },
        {
            name:'Prix',
            cell:(row)=>(
                <>
                    {row.Produit.prix} Ar
                </>
            ),
            // sortable:true
        },
        {
            name:'Quantite',
            cell:(row)=>(
                <>
                    {row.quantite} {row.Produit.Unite.abreviation}
                </>
            ),
            // sortable:true
        },
        {
            name:'Total',
            cell:(row)=>(
                <> {row.Produit.prix*row.quantite} Ar</>
            ),
            // sortable:true
        },
        {
            name:'Provenance',
            selector: row=>row.Produit.provenance,
            // sortable:true
        },
        {
            name:'Fournisseur',
            selector: row=>(<>
                {row.Produit.Fournisseur.nom} {row.Produit.Fournisseur.prenom}
            </>),
            // sortable:true
        },
        {
            name:'Numero fournisseur',
            selector: row=>row.Produit.Fournisseur.telephone,
            // sortable:true
        },
    ]

    //Affiche detail commande
    const [modal_detail_historique, setModal_detail_historique]=useState(false)
    const [detail_com, setDetail_Com]=useState([])
    const [date_com, setDate_com]=useState('')
    const total_prix = detail_com.reduce((total, item) => {
        return total + (item.Produit.prix * item.quantite);
    }, 0);

     //Detail de donnee
     const [id_commande, setId_commande]=useState('')
     const [message, setMessage]=useState('')
     const [alert_message, setAlert_message]=useState(false)
     const [modal_detail, setModal_detail]=useState(false)

    const fonction_detail = async (id_commande, date) => {
        // alert(id_commande)/
        try {
            url.get(`/commande/affiche_detail_commande_client/${id_commande}`)
            .then((response) => {
                setDetail_Com(response.data);
                setDate_com(date)
                setModal_detail(!modal_detail)
            })
            .catch((error) => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    }

    //Affiche de donnee
    const [donnee, setDonnee]=useState([])
    const [filtre_donnee, setFiltre_donnee]=useState([])
    async function Donnee() {
        try {
            const response = await url.get("/commande/affiche_commande_back");
            setDonnee(response.data);
            setFiltre_donnee(response.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(()=>{
        (async()=> await Donnee())()
    },[])
    const handlefilter = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const new_data = filtre_donnee.filter(row => 
            row.Client.nom.toLowerCase().includes(searchValue) || 
            row.lieu_livraison.toLowerCase().includes(searchValue)
        );
        setDonnee(new_data);
    };

    //Valide le donnee
    const [reference, setReference]=useState('')
    const [nom, setNom]=useState('')
    const [lieu_livraison, setLieu_livraison]=useState('')
    const [methode_paye, setMethode_paye]=useState('')
    const [modal_valide, setModal_valide]=useState(false)
    function valide_donnee(id_commande){
        setId_commande(id_commande)
        setModal_valide(!modal_valide)
    }
    async function valide_utilisateur(){
        await url.post("/commande/valide_commande", {
            id_commande
        })
        await Donnee()
        .then(response => {
            console.log(response.data.message);
        })
        .catch(error => {
            console.error(error);
        });
        setModal_valide(!modal_valide)
        setMessage("Validation avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
    }

    //Valide le donnee
    const [modal_payement, setModal_payement]=useState(false)
    const [id_payement, setId_payement]=useState('')
    function payement_fonct(id_payement ,id_commande, nom, lieu_livraison, methode_paye, date_pub){
        setId_payement(id_payement)
        setNom(nom)
        setLieu_livraison(lieu_livraison)
        setMethode_paye(methode_paye)
        setDate_com(date_pub)
        try {
            url.get(`/commande/affiche_detail_commande_client/${id_commande}`)
            .then((response) => {
                setDetail_Com(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
        setModal_payement(!modal_payement)
    }
    async function payement(){
        window.print();
        await url.post("/commande/valide_payement", {
            id_payement
        })
        await Donnee()
        .then(response => {
            setMessage("Validation avec succee")
            // setMessage(response.data)
            setAlert_message(true)
            setTimeout(()=>{
                setAlert_message(false)
            }, 1500)
        })
        .catch(error => {
            console.error(error);
        });
        setModal_payement(!modal_payement)
    }

    // supprimer le donner
    const [modal_suprime, setModal_suprime]=useState(false)
    function fonction_supprime(id_commande){
        setModal_suprime(!modal_suprime)
        setId_commande(id_commande)
    }
    async function supprime_utilisateur(){
        await url.delete(`/commande/supprime_commande/${id_commande}`)
        await Donnee()
        setMessage("Suppression avec succee")
        setAlert_message(true)
        setTimeout(()=>{
            setAlert_message(false)
        }, 1500)
        setModal_suprime(!modal_suprime)
    }

  return (
    <>
        {alert_message &&<p className='message_alert'>{message}</p>}
        <div className='tete_body mt-3 row'>
            <h4 className='col-lg-6 col-md-6'>Gestion de commande</h4>
            <div className='col-lg-6 col-md-6'>
                <input type='text' 
                placeholder='Recherche...'
                onChange={handlefilter}
                />
                {/* <button className='mr'>
                    <i className='fa fa-plus'/>
                    Ajouter
                </button> */}
            </div>
        </div>

        <div className='body'>
            <DataTable
                columns={column}
                data={donnee}
                // selectableRows
                fixedHeader
                pagination
                customStyles={style}
            ></DataTable>
        </div>

        {/* modal detail */}
        {modal_detail &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_detail()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Détail du commande </h4>
                    <div className='donne_affiche'>
                    <DataTable
                        columns={column_detail}
                        data={detail_com}
                        // selectableRows
                        fixedHeader
                        pagination
                        customStyles={style}
                    ></DataTable>
                    </div>
                    <label>Prix total : </label> <label>{total_prix} Ar</label>
                    <span className='btn_detail'>
                        <button onClick={()=>fonction_detail()}>Fermer</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal valide */}
        {modal_valide &&(
            <div className='modal_detail'>
                <div onClick={()=>valide_donnee()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Validation</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la validation de l'utilisateur</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>valide_donnee()}>Fermer</button>
                        <button onClick={()=>valide_utilisateur(id_commande)}>Valider</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal payement */}
        {modal_payement &&(
            <div className='modal_detail'>
                <div onClick={()=>payement_fonct()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Payement</h4>
                    <div className='donne_affiche p-4'>
                        <div className='tete_facture'>
                            <img src='images/logo_fond_blanc.jpeg'/>
                            <div>
                                <h3>FACTURE</h3>
                                <label>{date_com}</label>
                            </div>
                        </div>
                        <hr/>
                        <div className='identite'>
                            <div>
                                <span>Nom du client : {nom}</span><br/>
                                <span>Lieu de livraison : {lieu_livraison}</span>
                            </div>
                            <div>
                                <label>Type de payement : {methode_paye}</label> <br/>
                                <label>Etat : Payer</label>
                            </div>
                        </div>
                        <hr/>
                        <div className='mb-2'>
                            <DataTable
                                columns={column_detail}
                                data={detail_com}
                                // selectableRows
                                fixedHeader
                                // pagination
                                customStyles={style}
                            ></DataTable>
                        </div>
                        <label>Prix total : </label> <label>{total_prix} Ar</label>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>payement_fonct()}>Fermer</button>
                        <button onClick={()=>payement()}>Valider</button>
                    </span>
                </div>
            </div>
        )}

        {/* modal supprimer */}
        {modal_suprime &&(
            <div className='modal_detail'>
                <div onClick={()=>fonction_supprime()} className='ferme_modal'></div>
                <div className='body_modal_detail'>
                    <h4 align="center">Suppression</h4>
                    <div className='donne_affiche'>
                        <div>
                            <h6 align="center">Confirmer la suppression de l'utilisateur</h6>
                        </div>
                    </div>
                    <span className='btn_double'>
                        <button onClick={()=>fonction_supprime()}>Fermer</button>
                        <button onClick={()=>supprime_utilisateur(id_commande)}>Supprimer</button>
                    </span>
                </div>
            </div>
        )}
    </>
  )
}
